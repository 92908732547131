<template>
  <div class="main-content">
    
    <div class="summary-container">
      <h2>Summary</h2>

      <div class="summary-learners">
          <p><span>Total learners in this section: </span>{{ total_learners }}</p>
          <p><span>Total modules in this section: </span>{{ total_modules }}</p>
          <p  v-if="this.total_modules > 1"><span>Total learners who have completed all modules: </span>{{ total_completed_learners }} [{{ total_completed_percent}}%]</p>
          <p  v-else><span>Total learners who have completed this module: </span>{{ total_completed_learners }} [{{ total_completed_percent}}%]</p>
      </div>

      <div class="modules_completion" v-if="this.total_modules > 1">
        <table class="modules_completion_table">
          <thead>
            <th>Module</th>
            <th>Completion rate</th>
            <th>Learners completed</th>
          </thead>
          <tbody>
            <tr
              v-for="(module) in modules_summary" 
              :key="module.id"
            >
              <td>{{ module.module_name }}</td>
              <td>{{ module.completion_rate }}%</td>
              <td>{{ module.completed_count }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </div>
    <div class="details-container">
      <h2>Learner Details</h2>
      <div class="details-header">
        <!--<v-date-picker class="inline-block h-full" v-model="date">
          <template v-slot="{ inputValue, togglePopover }">
            <div class="flex items-center">
              <button
                class="datePickerBtn border"
                @click="togglePopover()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </button>
              <input
                :value="inputValue"
                class="datePickerInput"
                readonly
              />
            </div>
          </template>
        </v-date-picker>-->
        <input class="search-input" type="text" placeholder="Search learners" v-model="searchQuery" @keyup.enter="searchLearner(searchQuery)"/>
        <div class="export-csv-container">
          <button class="btn" @click="downloadTableData()">Download Learner Data</button>
          <!--<button class="settings-btn" @click="$modal.show('exportCSVSettingsModal')">CSV export settings</button>-->
        </div>
      </div>
      <div ref="completionTable" id="main-data-table"></div>
    </div>
  </div>
</template>
<script>
import api from "../services/api";
import Tabulator from "tabulator-tables";
import moment from "moment";
import { utilFunctionService } from "@/utils/utils.service";
import XLSX from "xlsx";
window.XLSX = XLSX;

export default {
  name: "Completion-Reporting",
  components: {
  },
  data() {
    return {
      avg_grades: [],
      options: {},
      searchQuery: "",
      date: new Date(),
      course_title: "",
      course_start: "",
      course_end: "",
      section: "",
      total_learners: '',
      total_modules: '',
      total_completed_learners: '',
      total_completed_percent: '',
      users: [],
      modules: [],
      learners: [],
      columns_data: [], 
      tabulator: null
    };
  },
  beforeMount() {
    this.section = JSON.parse(localStorage.getItem("section"));
    utilFunctionService.showLoader();
    api
    .getCompletionReportData(this.section.id)
    .then((res) => {
      //console.log(res.data, "*** res.data in beforeMount");
      this.course_title = res.data.data.attributes.course_name;

      if (res.data.data.attributes.course_start_date) {
        this.course_start = moment(res.data.data.attributes.course_start_date).format("dddd, MMMM Do YYYY");
      } else {
        this.course_start = "N/A"
      }
      if (res.data.data.attributes.course_end_date) {
        this.course_end = moment(res.data.data.attributes.course_end_date).format("dddd, MMMM Do YYYY");
      } else {
        this.course_end = "N/A"
      }

      this.total_learners = res.data.data.attributes.total_learners_count;
      this.total_completed_learners = res.data.data.attributes.total_learners_completed_all_modules;
      this.total_completed_percent = res.data.data.attributes.percent_learners_finished_all_modules;
      this.total_modules = res.data.data.attributes.total_modules;
      this.modules_summary = res.data.data.attributes.modules_summary;
      this.users = res.data.data.attributes.section_learners;
      console.log(this.users, "*** this.users");
      this.columns_data = [
        {title:"Name", field:"learner_name", frozen: true},
        {title:"Email", field:"learner_email"}, 
        {title: "Overall", 
          columns: [
            {title:"Start Date", field:"learner_start_date"},
            {title:"End Date", field:"learner_completion_date"},
            {title:"% Complete", field:"percent_complete"}
          ]
        }
      ]

      if (this.total_modules > 1) {
        this.users[0].learner_modules_data.slice(0, this.total_modules).forEach((module_data, index) => {
          const module_name = this.users[0][`learner_module_${index + 1}_name`];
          const module_start_date = `learner_module_${index + 1}_start_date`;
          const module_end_date = `learner_module_${index + 1}_end_date`;
          const module_percent_complete = `learner_module_${index + 1}_percent_complete`;
          const new_data = {
            title: module_name,
            columns: [
              { title: "Start Date", field: module_start_date },
              { title: "End Date", field: module_end_date },
              { title: "% Complete", field: module_percent_complete }
            ]
          }
          ;

          this.columns_data.push(new_data);
        });
      }

      if (this.total_modules > 1) {
        this.tabulator = new Tabulator(this.$refs.completionTable, {
          data: this.users, 
          autoColumns:false,
          columnHeaderVertAlign:"bottom",
          layout: "fitData",
          layoutColumnsOnNewData: true,
          resizableColumns: true,
          renderHorizontal: "virtual",
          height: "600px",
          persistence: {
            sort: true, 
            filter: true, 
            columns: true,
          },
          selectable: true,
          downloadConfig: {
            columnHeaders: true, 
            columnGroups: true, 
            rowGroups: false, 
            columnCalcs: false, 
            dataTree: true
          },
          columns: this.columns_data
        })
      } else {
        this.tabulator = new Tabulator(this.$refs.completionTable, {
          data: this.users, 
          autoColumns:false,
          columnHeaderVertAlign:"bottom",
          layout: "fitColumns",
          layoutColumnsOnNewData: true,
          resizableColumns: true,
          renderHorizontal: "virtual",
          height: "600px",
          persistence: {
            sort: true, 
            filter: true, 
            columns: true,
          },
          selectable: true,
          downloadConfig: {
            columnHeaders: true, 
            columnGroups: true, 
            rowGroups: false, 
            columnCalcs: false, 
            dataTree: true
          },
          columns: this.columns_data
          
        })
      }
      
      utilFunctionService.hideLoader();

    })
    .catch(() => {});
  },
  watch: {
    searchQuery(query) {
      setTimeout(function () {
        this.searchLearner(query);
      }.bind(this), 1000)
    }
  },
  methods: {
    openCSVSettings() {
      this.$modal.open('exportCSVSettingsModal');
    },
    searchLearner(learnerQuery) {
      this.tabulator.setFilter("learner_name", "like", learnerQuery);
    },
    downloadTableData() {
      //console.log(this.TabulatorComponent, "*** this.TabulatorComponent");
      let spreadsheetName = this.section.attributes.name + " Completion Report Export.xlsx";
      this.tabulator.download("xlsx", spreadsheetName);
      //this.tabulator.download("csv", "data.csv");
    },
    getStudentsByDate(startDate, endDate) {
      this.users = this.users.filter(user => user.start_date <= startDate && user.end_date >= endDate);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "tabulator-tables/dist/css/tabulator.css";
.main-content {
  padding: 0;
  width: 100%;
}

.completion-grid {
  width: 100%;
  height: 500px;
  margin-top: 32px;
}
.selection-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  .custom-select {
    width: 35%;
  }
}
.summary-container {
  margin-bottom: 64px;

  p {
    margin-bottom: 16px;
    span {
      font-weight: bold;
    }
  }

  h2 {
    font-family: 'robotobold', sans-serif;
    font-size: 18px;
    text-transform: capitalize;
  }
  
  .summary {
    margin: 2em 0 0 0;
    
  }

  .modules_completion {
    padding-top: 2em;
    .modules_completion_table {
      width: 100%;
      padding-top: 1em;
      thead {
        border-bottom: 1px solid #333;
        th {
          padding-bottom: 0.5em;
        }
      }
      tr {
        td {
          padding-right: 1em;
          padding-top: 0.5em;
          width: 20%;
        }
      }
    
  }
}
  .summary-learners {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 1em;
  }
}
.details-container {
  h2 {
    font-family: 'robotobold', sans-serif;
    font-size: 18px;
    text-transform: capitalize;
  }
  h3 {
    font-size: 16px;
    font-family: 'robotobold', sans-serif;
    text-transform: capitalize;
  }
  .details-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    .search-input {
      color: #4a5568;
      padding: 0.5rem 0.5rem;
      border-width: 1px;
      border-radius: 0.25rem;
      background-color: #fff;
      margin-left: 16px;
    }
  }
  .export-csv-container {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  .datePickerBtn {
    color: #3182ce;
    padding: 0.5rem;
    border-width: 1px;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #bee3f8;
    background-color: #ebf8ff;
    svg {
      fill: currentColor;
      width: 1rem;
      height: 1rem;
    }
  }
  .datePickerInput {
    color: #4a5568;
    padding: 0.5rem 0.5rem;
    border-width: 1px;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #fff;
  }
}
.export-csv-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .btn {
    border: 3px solid #3dbc9e;
    background: #fff;
  }
  .settings-btn {
    margin-top: 16px;
    border: none;
    font-weight: bold;
    background: none;
  }
}

.tabulator {
  margin-top: 16px;
  background: #fff;
  font-size: 1em;
  
}


</style>
