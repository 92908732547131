<template>
  <div class="learner-container">
    <div class="header">
      <h1 class="learn-title">
        {{ lo_details.attributes.learning_object.attributes.name }}
      </h1>
      <h2
        class="learn-info"
        v-html="lo_details.attributes.learning_object.attributes.description"
      ></h2>
    </div>
    <div class="email-area" v-if="emailChain.length > 0">
      <div class="email-container">
        <div
          class="email-chain"
          v-for="(email, index) in emailChain"
          :key="email.id"
        >
          <div class="email-header">
            <p>Subject: {{ emailSubject }}</p>
            <p>To: {{ email.toCharacter }}</p>
            <p>From: {{ email.fromCharacter }}</p>
            <p v-if="index === 0 && emailccCharacters.length >= 1">CC: {{ emailccCharacters }}</p>
          </div>
          <div class="email-body">
            <div v-html="email.message"></div>
          </div>
          <div class="email-body">
            <div style="white-space: pre-line">{{ email.response }}</div>
          </div>
          <div class="email-divider" v-if="index + 1 !== emailChain.length">
            <div>Reply from {{ email.toCharacter }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="assessment-container">
      <div class="final-feedback-title">
        <h1>Conversation Assessment</h1>
      </div>
      <div class="assessment-content">
        <div v-if="ifLearnerAssessments" class="container">
          <div v-for="(feedback, name, index) in feedbackData" class="row" :key="feedback[index]">
            <h2>{{ feedback.data.attributes.custom_label || feedback.data.attributes.skill_set_title }}</h2>
            <p class="feedback-result" v-if="feedback.data.attributes.show_pass_fail_labels">{{ feedback.data.attributes.assessment_result || feedback.data.attributes.hit_feed_req_item_label }}</p>
            <p v-if="feedback.data.attributes.show_points">{{ feedback.data.attributes.earned_points }}/{{ feedback.data.attributes.possible_points }}</p>
            <p v-html="feedback.data.attributes.llm_feedback"></p>
          </div>
        </div>
        <div v-else class="container">
          <div v-for="(feedback, name, index) in feedbackData" class="row" :key="feedback[index]">
            <h2 v-if="feedback.show_labels">{{ feedback.skill_label || feedback.skill_name }}</h2>
            <h2 v-else>{{ feedback.skill_name }}</h2>
            <p v-if="feedback.show_labels" class="feedback-result">{{ feedback.result }}</p>
            <p>{{ feedback.feedback }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
export default {
  name: "llm-email",
  props: ["active_data", "user_details", "lo_details"],
  data() {
    return {
      messages: [
        {
          question: "",
          answer: "",
          response: "",
        },
      ],
      userAnswer: "",
      loading: false,
      assessmentLoading: false,
      feedback: "",
      hasStarted: false,
      finishedLoading: false,
      numberOfAttempts: 0,
      attemptsLimit: 3,
      llmComplete: false,
      showAssessment: false,
      showFeedback: false,
      dialogicQuestions: [],
      llmChatImageEnabled: false,
      llmChatImageURL: "",
      emailBody: "",
      emailSubject: "",
      emailChain: [],
      emailToCharacter: "",
      emailFromCharacter: "",
      emailIterationsLimit: 0,
      simpleToolbar: [["bold", "italic", "underline"]],
      studentName: "",
      characterName: "",
      emailccCharacters: [],
      feedbackData: "",
      ifLearnerAssessments: false,
    };
  },
  methods: {
    submitAssessmentRequest() {
      api.getLlmEmailAssessment(this.lo_details.attributes.current_evaluation_id).then((res) => {
        console.log(res);
        const response = res.data.data;
        if (response.attributes.learner_assessments.length > 0) {
          this.feedbackData = response.attributes.learner_assessments;
          this.ifLearnerAssessments = true;
        } else {
          this.feedbackData = response.attributes.assessment_results;
        }
      });
    },
    resetLlmConversation() {
      let payload = {
        student_id: this.user_details.id,
        lo_id: this.active_data.attributes.learning_object_id,
      };
      api.resetLlmConversation(payload).then((res) => {
        console.log(res);
        utilFunctionService.showSuccess("Reset Successfully. Page will refresh in 2 seconds.");
        this.assessmentLoading = false;
        setTimeout(() => {
          location.reload();
        }, 2000);
      });
    },
    getLlmConversation() {
      let payload = {
        lo_id: this.active_data.id,
        user_learn_obj_id : this.lo_details.id
      };
      api.getLlmChatHistory(payload).then((res) => {
        let chatHistoryData = res.data.data;
        chatHistoryData.forEach((chatEl) => {
          this.hasStarted = true;
          let response = "";
          if (chatEl.attributes.response.length > 0) {
            response = chatEl.attributes.response[0].response;
          }
          console.log(chatEl.attributes.subject);
          this.emailChain.push({
            fromCharacter: this.studentName,
            toCharacter: this.characterName,
            message: chatEl.attributes.body,
            subject: chatEl.attributes.subject,
            response: "",
            id: this.emailChain.length + 1,
          });
          this.emailChain.push({
            fromCharacter: this.characterName,
            toCharacter: this.studentName,
            subject: chatEl.attributes.subject,
            message: "",
            response: response,
            id: this.emailChain.length + 1,
          });
        });
        this.assessmentLoading = false;
      });
    },
  },
  mounted() {
    this.llmComplete = this.active_data.attributes.complete;
    let cardDetail =
      this.active_data.attributes.card_detail;
    this.characterName = cardDetail.attributes.character_name;
    this.studentName = this.user_details.attributes.full_name;
    this.emailToCharacter = this.characterName;
    this.emailFromCharacter = this.studentName;
    this.emailccCharacters = cardDetail.attributes.cc_character[0];
    this.emailIterationsLimit = cardDetail.attributes.email_iteration_limit;

    this.getLlmConversation();
    this.submitAssessmentRequest();
  },
};
</script>

<style scoped lang="scss">
.learner-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.messages-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 45px;
}
.bubble {
  border-radius: 15px;
  padding: 16px;
  margin-bottom: 16px;
  max-width: 50%;
}
.learner-bubble {
  background: #3dbc9e;
  color: #faf9f6;
  font-weight: bold;
  justify-self: flex-end;
  align-self: flex-end;
  text-align: right;
  width: fit-content;
}
.robot-bubble {
  background: #3d9bbc;
  color: #faf9f6;
  font-weight: bold;
  justify-self: flex-start;
  align-self: flex-start;
  width: fit-content;
  position: relative;
}
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
}
.button {
  padding: 8px;
  border-radius: 5px;
  background: white;
  border: 2px solid green;
  margin-top: 16px;
}
.button:hover {
  background: green;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.up-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.up-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.feedback {
  width: 50%;
  margin: 25px auto;
  text-align: left;
  line-height: 1.6;
}
.spacer {
  display: block;
  width: 50%;
  margin: 0 auto;
  height: 1px;
  background: black;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
}

.messages-container {
  display: flex;
  width: 100%;
  padding: 10px;
  position: relative;
  min-height: 75px;
  max-height: 450px;
}
.input-area {
  color: black;
  width: 400px;
  min-height: 75px;
  padding: 16px;
  max-height: 450px;
}
.submit-button {
  border: none;
  font-size: 14px;
  padding: 6px 10px;
  border: 1px solid green;
  margin-top: 0;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin ball {
  @include center;
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgb(240, 240, 240);
  z-index: 2;
  margin-top: 4px;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
}

/*--------------------
Bounce
--------------------*/
@keyframes bounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.loading {
  padding: 16px;
  &::before {
    @include ball;
    border: none;
    animation-delay: 0.15s;
  }

  & span {
    display: block;
    font-size: 0;
    width: 20px;
    height: 10px;
    position: relative;

    &::before {
      @include ball;
      margin-left: -7px;
    }

    &::after {
      @include ball;
      margin-left: 7px;
      animation-delay: 0.3s;
    }
  }
}

@keyframes ball {
  from {
    transform: translateY(0) scaleY(0.8);
  }
  to {
    transform: translateY(-10px);
  }
}

.avatar {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 2px solid rgba(255, 255, 255, 0.24);
  margin-right: 16px;
  img {
    width: 100%;
    height: auto;
  }
}

.character-container {
  position: absolute;
  z-index: 1;
  top: -50px;
  left: 0px;
  background: #3d9bbc;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.character-name {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
}

.knowledge-base-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}
.knowledge-base-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.knowledge-base-item h5 {
  font-weight: bold;
}

.kb-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.text-area {
  width: 400px;
  font-family: sans-serif;
  height: 200px;
  margin-bottom: 18px;
  padding: 8px;
  line-height: 1.6;
}
.prompts-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.side-menu {
  display: flex;
  flex-direction: column;

  width: 20%;
  border-right: 1px solid black;
}
.menu-item {
  width: 85%;
  border: 1px solid black;
  margin-bottom: 0;
  border-radius: 5px;
  list-style-type: none;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
}
.menu-item:hover {
  background: black;
  color: white;
}
.prompts-edit {
  width: 80%;
  padding: 16px;
}
.prompts-container {
  width: 100%;
  margin-bottom: 32px;
}
.prompts-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}
.prompts-header h5 {
  font-weight: bold;
}
.kb_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
}
.kb_form--item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
}
.kb_form--itemhori {
  flex-direction: row;
}
.kb_form--item label {
  margin-bottom: 8px;
  font-weight: bold;
}

.kb_form--item p {
  margin-left: 16px;
  line-height: 0;
}
.kb_form--item textarea {
  width: 100%;
  font-family: sans-serif;
  height: 100px;
  margin-bottom: 18px;
  padding: 8px;
  line-height: 1.6;
}

.kb_form--item li {
  list-style-type: disc;
}

.kb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.kb-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}
.kb-input input {
  width: 50%;
  padding: 8px;
  border-radius: 5px;
}
.btn {
  border-radius: 5px;
  border: 1px solid black;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
  height: 50px;
}
.btn:hover {
  background: black;
  color: white;
}
.side-prompt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px 0 0;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.edit-kb {
  background: rgb(37, 61, 240);
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.edit-kb:hover {
  background: white;
  color: rgb(32, 52, 205);
  border: 1px solid rgb(32, 52, 205);
}

.delete-kb {
  background: rgb(255, 80, 80);
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.delete-kb:hover {
  background: white;
  color: rgb(199, 61, 61);
  border: 1px solid rgb(199, 61, 61);
}

.delete-button {
  background: rgb(255, 80, 80);
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid black;
  line-height: 0;
}
.delete-button:hover {
  background: white;
  color: rgb(199, 61, 61);
  border: 1px solid rgb(199, 61, 61);
}
.kb_item--column {
  display: flex;
  flex-direction: column;
}

.learner-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.messages-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1000px;
}
.bubble {
  border-radius: 15px;
  padding: 8px;
  margin-bottom: 16px;
  width: 50%;
}
.learner-bubble {
  background: green;
  color: white;
  font-weight: bold;
  justify-self: flex-end;
  align-self: flex-end;
}
.robot-bubble {
  background: blue;
  color: white;
  font-weight: bold;
  justify-self: flex-start;
  align-self: flex-start;
}
.input-area {
  color: black;
  width: 400px;
}
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
}
.button {
  padding: 8px;
  border-radius: 5px;
  background: white;
  border: 2px solid green;
  margin-top: 16px;
}
.button:hover {
  background: green;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.up-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.up-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.feedback {
  width: 50%;
  margin: 25px auto;
  text-align: left;
  line-height: 1.6;
}
.spacer {
  display: block;
  width: 50%;
  margin: 0 auto;
  height: 1px;
  background: black;
}

.prompt-reaction-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.prompt-reaction-container .prompts-body {
  justify-content: flex-start;
  gap: 16px;
}

.prompt-reaction-container button {
  width: 250px;
}

.testing-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
}

.email-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  // box-shadow: 0 12px 20px 0 rgb(0 0 0);
  padding: 40px 47px 60px;
}

.subject-line {
  font-family: "mulibold", sans-serif;
  height: auto;
  padding: 20px 25px;
  border-radius: 2px;
  background-color: #f2f8f6;
  font-size: 14px;
  color: #313030;
  border: 0;
  line-height: 1.71;
  width: 100%;
  margin-bottom: 32px;
}

.email-header {
  width: 100%;
  margin-bottom: 32px;
}

.email-body {
  width: 100%;
  margin-bottom: 32px;
}

.email-body .form-control {
  padding: 0;
}

.email-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.email-area {
  width: 100%;
}

.email-chain {
  width: 100%;
}

.email-divider {
  text-align: center;
  width: 100%;
  font-weight: bold;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 8px 0;
  margin-bottom: 32px;
}

.add-prompt-btn {
  width: 80%;
}

.assessmentKBTitle {
  font-weight: bold;
  margin-bottom: 8px;
  border-bottom: 2px solid black;
}

.email-button {
  margin-bottom: 16px;
}

.warning-text {
  color: #d70505;
  font-weight: bold;
  margin-bottom: 16px;
}

.complete-text {
  font-weight: bold;
  font-size: 16px;
}

.assessment-content {
  padding-top: 32px;
  p {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
}

h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.container {
  margin: 0 16px;
  width: 100%;
}
</style>


