<template>
  <div class="container-full" :key="refreshKey">
    <div class="site-content pt-0">
      <learner-central-side-menu
        :loList="loList"
        :loDetails="loDetails"
      ></learner-central-side-menu>
      <div class="main-content main-learner-content">
        <div class="main-breadcrumbs">
          <ul>
            <li>
              <router-link to="/active"
                >Dashboard
                <em class="icon-next"></em>
              </router-link>
            </li>
            <li>
              <router-link to="/learnerCentral">Learner Central</router-link>
            </li>
          </ul>
          <div class="breadcrumbs-btn">
            <div v-if="loDetails && loDetails.attributes">
              <button
                v-if="
                  (loDetails.attributes.learning_object.attributes.card_type ===
                    'submission' &&
                    (file || has_text)) ||
                  (loDetails.attributes.learning_object.attributes.card_type ===
                    'submission_email' &&
                    emailData.length > 0)
                "
                class="btn primary medium"
                @click="
                  setSubmissions('#/learnerSubmission', 'submission', 950, 780)
                "
                :class="[
                  loList[index].attributes.user_submission_feedback_status == 'draft'
                    ? 'notify-btn'
                    : '',
                ]"
              >
              <span v-if="loList[index].attributes.user_submission_lo_grade === null || loList[index].attributes.user_submission_lo_grade === '0.0'">
                  Not Yet Graded
              </span>
              <span v-else>
                <span v-if="loList[index].attributes.user_submission_feedback_status == 'draft'">
                  Graded, Not Sent
                </span>
                <span v-else>
                  Graded
                </span>
                
              </span>
              </button>
            </div>
            <div class="note-dropdown">
              <button
                class="btn primary medium"
                @click="resetLO"
                v-if="
                  this.cardType === 'dialogic' ||
                  (this.cardType === 'email' &&
                    this.loType === 'interaction') ||
                  this.cardType === 'quiz' ||
                  this.cardType === 'submission_email' ||
                  this.cardType === 'submission'
                "
              >
                Reset Task
              </button>
              <button
                class="btn primary medium dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ showDelete ? "Note" : "Add Note" }}
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenu2"
              >
                <div class="head">
                  <h4>{{ showDelete ? "Note" : "Add Note" }}</h4>
                  <a href="javascript:void(0);">
                    <em class="icon-close"></em>
                  </a>
                </div>
                <div class="content">
                  <textarea
                    class="form-control"
                    rows="4"
                    v-model="stickyNote"
                  ></textarea>
                </div>
                <div class="footer">
                  <button
                    class="btn danger"
                    @click="updateNote('remove')"
                    v-if="showNote"
                  >
                    Remove Note
                  </button>
                  <button class="btn primary" @click="updateNote('add')">
                    Save
                  </button>
                </div>
              </div>
              <button
                class="btn primary medium"
                type="button"
                @click="showFeedBack"
                v-if="finalFeedbackData.module_complete == 'completed'"
              >
                See Final Feedback
              </button>
            </div>
          </div>
        </div>
        <div class="main-photoUser" v-if="loDetails && loDetails.attributes">
          <div class="main-photoUserImage">
            <img
              :src="
                loDetails.attributes.user_section.attributes.user.attributes
                  .profile_url
              "
              v-if="
                loDetails.attributes.user_section.attributes.user.attributes
                  .profile_url
              "
            />
          </div>
          <div class="main-photoUserContent">
            <h4>
              {{
                loDetails.attributes.user_section.attributes.user.attributes
                  .first_name
              }}
            </h4>
          </div>
        </div>
        <div class="main-title" v-if="loDetails && loDetails.attributes">
          <h3>{{ loDetails.attributes.learning_object.attributes.name }}</h3>
        </div>
        <p
          class="learn-info"
          v-html="loDetails.attributes.learning_object.attributes.description"
          v-if="cardType !== 'chat'"
        ></p>

        <div class="plagiarism-container" v-if="isPlagiarised">
          <plagiarism-icon :amount="plagiarismAmount" />
          <h4>Plagiarism Detected</h4>
        </div>

        <div class="submission-update" v-if="cardType === 'submission'">
          <button
            class="btn primary"
            @click="showWritingAsessmentModal"
            v-if="loDetails.attributes.writing_assessment"
          >
            Writing Assessment Available
          </button>
        </div>

        <div
          class="main-paragraph"
          v-if="
            loDetails && loDetails.attributes && loDetails.attributes.complete
          "
        >
          <div>
            <div v-if="loType === 'llm_chat'">
              <llm-chat
                :active_data="loDetails.attributes.learning_object"
                :user_details="loDetails.attributes"
                :lo_details="loDetails"
              ></llm-chat>
            </div>
            <div v-if="loType === 'llm_dialogic'">
              <llm-dialogic
                :active_data="loDetails.attributes.learning_object"
                :user_details="loDetails.attributes"
                :lo_details="loDetails"
              ></llm-dialogic>
            </div>
            <div v-if="loType === 'llm_email'">
              <llm-email
                :active_data="loDetails.attributes.learning_object"
                :user_details="loDetails.attributes.user_section.attributes.user"
                :lo_details="loDetails"
              ></llm-email>
            </div>
            <div
              class="introduction"
              v-if="
                loDetails.attributes.learning_object.attributes.card_type ===
                'video'
              "
            >
              <div class="introduction-video">
                <div
                  class="wistia_responsive_wrapper data-video"
                  style="
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                  "
                >
                  <iframe
                    :src="
                      preIframUrl +
                      loDetails.attributes.learning_object.attributes
                        .card_detail.attributes.global_video.attributes
                        .wistia_code +
                      postIframUrl
                    "
                    title="Video.mp4"
                    class="wistia_embed"
                    name="wistia_embed"
                    allowtransparency="true"
                    scrolling="no"
                    allowfullscreen
                    mozallowfullscreen
                    webkitallowfullscreen
                    oallowfullscreen
                    msallowfullscreen
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
                <div class="introduction-titles">
                  <div class="introduction-intro">
                    <div class="introduction-time">
                      {{
                        loDetails.attributes.learning_object.attributes
                          .card_detail.attributes.global_video.attributes
                          .duration
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="intro-transcript"
                v-if="
                  loDetails &&
                  loDetails.attributes.learning_object.attributes.card_detail
                    .attributes.global_video.attributes.transcript
                "
              >
                <h5>Transcript:</h5>
                <read-more
                  more-str="Read more"
                  :text="
                    loDetails.attributes.learning_object.attributes.card_detail
                      .attributes.global_video.attributes.transcript
                  "
                  link="#"
                  less-str="Read less"
                  :max-chars="400"
                ></read-more>
              </div>
            </div>
            <div
              class="introduction"
              v-if="
                loDetails.attributes.learning_object.attributes.card_type ===
                'slide'
              "
            >
              <carousel
                :margin="0"
                :nav="true"
                :navText="[]"
                :autoplay="false"
                :autoHeight="false"
                :items="1"
                :dots="false"
                :loop="true"
              >
                <div
                  v-for="(img, i) in loDetails.attributes.learning_object
                    .attributes.card_detail.attributes.slider_images"
                  :key="img.id"
                >
                  <div
                    v-if="
                      loDetails.attributes.learning_object.attributes
                        .card_detail.attributes.has_caption
                    "
                    class="introduction-video-text"
                  >
                    {{ img.attributes.caption }}
                  </div>
                  <div class="introduction-video">
                    <img
                      :src="img.attributes.resource_url.large_version"
                      alt="mask"
                      class="intro-slide-video"
                    />
                    <div class="introduction-intro-bottom">
                      <div class="introduction-intro-count">
                        {{ i + 1 }}/{{
                          loDetails.attributes.learning_object.attributes
                            .card_detail.attributes.slider_images.length
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="introduction-progress dashboard-progress-bar">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :style="{
                          width: [
                            ((i + 1) * 100) /
                              loDetails.attributes.learning_object.attributes
                                .card_detail.attributes.slider_images.length +
                              '%',
                          ],
                        }"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </carousel>
            </div>
            <div
              v-if="
                loDetails.attributes.learning_object.attributes.card_type ===
                'file'
              "
            >
              <div class="modal-content">
                <div class="modal-body">
                  <div class="modal-header modal-print-header mb-4">
                    <div class="modal-print-row">
                      <div class="modal-print-nav">
                        <div class="data-results">
                          Page {{ page }} of {{ numPages }}
                        </div>
                        <div aria-label="PDF page navigation">
                          <ul class="pagination pdf-pagination">
                            <li class="page-item" v-if="page !== 1">
                              <a
                                class="page-link"
                                aria-label="Previous"
                                @click="page--"
                              >
                                &lt;
                              </a>
                            </li>

                            <li class="page-item" v-if="page < numPages">
                              <a
                                class="page-link"
                                aria-label="Next"
                                @click="page++"
                              >
                                &gt;
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="modal-print-right">
                        <button class="btn-focus loader" @click="rotate += 90">
                          &#x27F3;
                        </button>
                        <button class="btn-focus rotate" @click="rotate -= 90">
                          Rotate
                        </button>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%; align-items: center">
                    <div
                      v-if="loadedRatio > 0 && loadedRatio < 1"
                      style="
                        background-color: green;
                        color: white;
                        text-align: center;
                      "
                      :style="{ width: loadedRatio * 100 + '%' }"
                    >
                      {{ Math.floor(loadedRatio * 100) }}%
                    </div>

                    <pdf
                      ref="pdfRef"
                      :source="
                        loDetails.attributes.learning_object.attributes
                          .card_detail.attributes.resource_url
                      "
                      :page="page"
                      :rotate="rotate"
                      @num-pages="numPages = $event"
                      @link-clicked="page = $event"
                      @rendered="handleDocumentRender"
                    ></pdf>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                loDetails.attributes.learning_object.attributes.card_type ==
                'email'
              "
            >
              <div class="learner-section">
                <div class="learn-users">
                  <div
                    class="learn-userItem"
                    v-for="character in loDetails.attributes.learning_object
                      .attributes.card_detail.attributes.to_characters"
                    :key="'toCharacter' + character.id"
                  >
                    <div class="learn-label">To</div>
                    <div class="learn-userImage">
                      <div class="learn-image">
                        <img
                          :src="character.attributes.char_photo_url.thumbnail"
                          v-if="character.attributes.char_photo_url.thumbnail"
                          alt="image"
                        />
                      </div>
                      <div class="learn-title">
                        <h5>{{ character.attributes.char_full_name }}</h5>
                        <p>{{ character.attributes.world_role }}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="learn-userItem"
                    v-for="character in loDetails.attributes.learning_object
                      .attributes.card_detail.attributes.cc_characters"
                    :key="character.id"
                  >
                    <div class="learn-label">CC</div>
                    <div class="learn-userImage">
                      <div class="learn-image">
                        <img
                          :src="character.attributes.char_photo_url.thumbnail"
                          v-if="character.attributes.char_photo_url.thumbnail"
                          alt="image"
                        />
                      </div>
                      <div class="learn-title">
                        <h5>{{ character.attributes.char_full_name }}</h5>
                        <p>{{ character.attributes.world_role }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                loDetails.attributes.learning_object.attributes.card_type ==
                  'email' &&
                loDetails.attributes.learning_object.attributes
                  .learning_object_type == 'plot_point'
              "
            >
              <h4>
                Subject:
                {{
                  loDetails.attributes.learning_object.attributes.card_detail
                    .attributes.title
                }}
              </h4>
              <div class="learn-paragraph mt-4">
                <div
                  class="emailBody"
                  v-if="loDetails.attributes.email_variation"
                  v-html="
                    replaceLearnerToken(loDetails.attributes.email_variation)
                  "
                ></div>
              </div>
            </div>

            <div
              v-if="
                loDetails.attributes.learning_object.attributes.card_type ==
                'text'
              "
            >
              <div class="learn-paragraph mt-4">
                <div
                  class="emailBody"
                  v-if="
                    loDetails.attributes.learning_object.attributes.card_detail
                      .attributes.description
                  "
                  v-html="
                    loDetails.attributes.learning_object.attributes.card_detail
                      .attributes.description
                  "
                ></div>
              </div>
            </div>

            <div
              v-if="
                loDetails.attributes.learning_object.attributes.card_type ==
                  'email' &&
                loDetails.attributes.learning_object.attributes
                  .learning_object_type == 'interaction'
              "
            >
              <div v-if="emailLists.length > 0">
                <div
                  class="learn-object"
                  v-for="(email, i) in emailLists"
                  :key="email.id"
                >
                  <div class="learn-object-forward">
                    <div class="learn-object-tag">
                      Subject: {{ filterEmailIndex[i].sub }}
                    </div>
                    <div class="learn-object-head">
                      <div
                        class="learn-object-col"
                        v-for="character in toCharacters"
                        :key="character.id"
                      >
                        <div class="learn-object-label">To</div>
                        <div class="learn-object-user">
                          <img
                            :src="character.attributes.char_photo_url.thumbnail"
                            v-if="character.attributes.char_photo_url.thumbnail"
                            alt="image"
                          />
                        </div>
                        <div class="learn-object-titles">
                          <h5>{{ character.attributes.char_full_name }}</h5>
                          <p>{{ character.attributes.world_role }}</p>
                        </div>
                      </div>

                      <span v-if="ccCharacters.length">
                        <div class="learn-object-col">
                          <div class="learn-object-label">CC</div>
                          <div
                            class="cc_char_card_small"
                            v-for="cc_character in ccCharacters"
                            :key="cc_character.id"
                          >
                            <div class="learn-object-user">
                              <img
                                :src="
                                  cc_character.attributes.char_photo_url
                                    .thumbnail
                                "
                                v-if="
                                  cc_character.attributes.char_photo_url
                                    .thumbnail
                                "
                                alt="image"
                              />
                            </div>
                            <div class="learn-object-titles">
                              <h5>
                                {{ cc_character.attributes.char_full_name }}
                              </h5>
                              <p>{{ cc_character.attributes.world_role }}</p>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="learn-object-paragraph">
                      <div class="learn-object-desc">
                        <p
                          v-html="replaceLearnerToken(filterEmailIndex[i].msg)"
                        ></p>
                      </div>
                    </div>
                    <!--<div>
                      To make this icon thing, use JS to to instances of plagiarism-detected and add the icon programmatically
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Morbi lobortis vestibulum risus aliquam
                        mattis. Nulla sollicitudin massa a eros bibendum
                        pellentesque. Duis blandit a velit et vulputate.
                        Curabitur blandit libero lorem, non imperdiet dui
                        cursus quis.<mark
                          class="plagiarism-detected"
                          @click="plagiarismModal($event)"
                          >Sed viverra, diam sit amet eleifend ornare, enim
                          nibh tristique sapien, eget vestibulum lacus diam
                          quis mi.<plagiarism-icon :amount="1"
                        /></mark>
                        Sed facilisis magna in nunc dictum, sed suscipit
                        erat dictum. Integer fringilla nulla et nulla
                        dictum, ullamcorper tincidunt eros egestas. Sed
                        commodo tellus ipsum. Proin et dui id turpis rutrum
                        convallis. Phasellus a est venenatis ligula dictum
                        ultrices. Curabitur sagittis nisl sed sapien commodo
                        vulputate.
                      </p>
                    </div> -->
                  </div>

                  <div
                    class="learn-object-reply"
                    v-for="item in filterEmailIndex[i].arr"
                    :key="item.id"
                  >
                    <div class="learn-reply-head">
                      <span
                        >Reply from
                        {{
                          item.attributes.character.attributes.char_full_name
                        }}</span
                      >
                    </div>

                    <div class="learn-object-tag">
                      Reply: {{ email.attributes.email_subject }}
                    </div>
                    <div class="learn-object-head">
                      <div class="learn-object-col">
                        <div class="learn-object-user">
                          <img
                            :src="item.attributes.character.profile_url"
                            v-if="item.attributes.character.profile_url"
                            alt="image"
                          />
                        </div>
                        <div class="learn-object-titles">
                          <h5>{{ item.attributes.character.full_name }}</h5>
                        </div>
                      </div>
                    </div>

                    <div class="learn-object-paragraph">
                      <div class="learn-object-desc">
                        <div
                          class="emailBody"
                          v-html="replaceLearnerToken(item.attributes.response)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="learn-object"
                v-if="
                  emailLists.length == 0 ||
                  (nextIteration && !ootoResponseStatus)
                "
              >
                <div class="learn-object-editable">
                  <div class="learn-object-head">
                    <div
                      class="learn-object-col"
                      v-for="character in toCharacters"
                      :key="character.id"
                    >
                      <div class="learn-object-label">To</div>
                      <div class="learn-object-user">
                        <img
                          :src="character.attributes.char_photo_url.thumbnail"
                          v-if="character.attributes.char_photo_url.thumbnail"
                          alt="image"
                        />
                      </div>
                      <div class="learn-object-titles">
                        <h5>{{ character.attributes.char_full_name }}</h5>
                        <p>{{ character.attributes.world_role }}</p>
                      </div>
                    </div>

                    <span v-if="ccCharacters.length">
                      <div class="learn-object-col">
                        <div class="learn-object-label">CC</div>
                        <div
                          class="cc_char_card_small"
                          v-for="cc_character in ccCharacters"
                          :key="cc_character.id"
                        >
                          <div class="learn-object-user">
                            <img
                              :src="
                                cc_character.attributes.char_photo_url.thumbnail
                              "
                              v-if="
                                cc_character.attributes.char_photo_url.thumbnail
                              "
                              alt="image"
                            />
                          </div>
                          <div class="learn-object-titles">
                            <h5>
                              {{ cc_character.attributes.char_full_name }}
                            </h5>
                            <p>{{ cc_character.attributes.world_role }}</p>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="learn-object-editor">
                    <div class="form-group" v-if="!nextIteration">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Add Subject"
                        v-model="emailSubject"
                      />
                    </div>
                    <div class="form-group">
                      <vue-editor
                        class="editor-control disable"
                        :editorToolbar="fullToolbar"
                        v-model="emailDesc"
                        @input="emailBody()"
                      ></vue-editor>
                    </div>
                    <div class="learn-object-send">
                      <button
                        href="javascript:void(0);"
                        :disabled="true"
                        class="btn primary"
                      >
                        {{ emailSendBtnText }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="learn-object yellow-note" v-if="ootoResponseStatus">
                <p id="note-text">{{ this.ootoNotice }}</p>
                <p><br /></p>
                <p>In the meantime please review the content below.</p>
              </div>

              <div
                class="learn-btn text-right mt-4"
                v-if="
                  !nextIteration &&
                  !ootoResponseStatus &&
                  emailLists.length != 0
                "
              >
                <button class="btn primary" @click="showEmailFeedBack">
                  See Feedback
                  <em class="icon-arrow-right"></em>
                </button>
              </div>

              <div class="interstitial-footer" v-if="showInterstitial">
                <div
                  class="interstitial-footer-item"
                  v-for="item of interstitialContents"
                  :key="item.key"
                >
                  <a
                    @click="
                      selectedInterstitial(item);
                      show('gatheringInformation');
                    "
                    >{{ item.attributes.title
                    }}<span
                      v-if="
                        item.attributes.content_details.type == 'text_learn_obj'
                      "
                      >Text</span
                    >
                    <span
                      v-if="
                        item.attributes.content_details.type ==
                        'video_learn_obj'
                      "
                      >Video</span
                    >
                    <span
                      v-if="
                        item.attributes.content_details.type ==
                        'slide_learn_obj'
                      "
                      >Slide</span
                    >
                    <span
                      v-if="
                        item.attributes.content_details.type == 'file_learn_obj'
                      "
                      >File</span
                    >
                  </a>
                </div>
              </div>
            </div>

            <modal
              name="feedbackTaskFinal"
              class="modal-bottomScreen"
              width="100%"
              height="auto"
              :scrollable="true"
            >
              <div
                class="modal-content modal-feedback-content modal-score-content"
                v-if="emailfeedbackResponse"
              >
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('feedbackTaskFinal')"
                >
                  <em class="icon-close"></em>
                </button>
                <div class="modal-slide-content">
                  <div class="modal-body pt-0">
                    <div class="d-flex flex-wrap w-100 justify-content-between">
                      <h3>Feedback</h3>
                      <div class="d-flex">
                        <p>
                          Number of iterations:{{
                            emailfeedbackResponse.attributes.iteration
                          }}
                        </p>
                        <a
                          href="javascript:void(0);"
                          @click="$modal.show('iterationInfo')"
                          class="feedback-info"
                          >i</a
                        >
                      </div>
                    </div>

                    <div
                      class="feedback-items mastery"
                      v-for="values in emailfeedbackResponse.attributes
                        .learner_debrief_items"
                      :key="values.id"
                    >
                      <div
                        class="feedback-icon"
                        v-if="
                          !emailfeedbackResponse.attributes
                            .hide_assessment_label
                        "
                      >
                        <img
                          :src="
                            values.attributes.email_assessment_item.attributes
                              .assessment_icon_url
                          "
                          alt="suggestion"
                        />
                      </div>
                      <div class="feedback-content">
                        <div
                          class="feedback-head"
                          v-if="
                            !emailfeedbackResponse.attributes
                              .hide_assessment_label
                          "
                        >
                          {{
                            values.attributes.email_assessment_item.attributes
                              .assessment_label
                          }}
                        </div>
                        <h4>
                          {{ values.attributes.global_skill.name }}
                          <a
                            @click="
                              modalInfoModal(
                                'feedbackInfo',
                                values.attributes.global_skill.name,
                                values
                              )
                            "
                            class="feedback-info"
                            >i</a
                          >
                        </h4>
                        <div
                          class="emailBody"
                          v-html="values.attributes.debrief_content"
                        ></div>
                        <div
                          class="col-12 px-0"
                          v-for="item in values.attributes.email_assessment_item
                            .attributes.adaptive_contents"
                          :key="item.id"
                        >
                          <!-- suggestion container slide  -->
                          <div
                            class="suggestion-container"
                            v-if="
                              item.attributes.content_details.type ==
                              'slide_learn_obj'
                            "
                          >
                            <h5>Suggested Content</h5>
                            <div class="suggestion-row">
                              <div class="suggestion-slide">
                                <a
                                  @click="
                                    $modal.show('suggestedContentModal', {
                                      data: item.attributes.content_details
                                        .attributes.slider_images,
                                      type: 'slide',
                                      title: item.attributes,
                                    })
                                  "
                                >
                                  <img
                                    :src="
                                      item.attributes.content_details.attributes
                                        .slider_images[0].attributes
                                        .resource_url.thumbnail
                                    "
                                    alt="suggestion"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            class="suggestion-container"
                            v-if="
                              item.attributes.content_details.type ==
                              'text_learn_obj'
                            "
                          >
                            <h5>Suggested Content(text)</h5>
                            <div class="suggestion-row">
                              <div
                                class="suggestion-slide"
                                v-html="
                                  item.attributes.content_details.attributes
                                    .title
                                "
                              ></div>
                            </div>
                          </div>
                          <!-- suggestion container pdf  -->
                          <div
                            class="suggestion-container"
                            v-if="
                              item.attributes.content_details.type ==
                              'file_learn_obj'
                            "
                          >
                            <h5>Suggested Content(file)</h5>
                            <div class="suggestion-row">
                              <div class="suggestion-pdf">
                                <a
                                  :href="
                                    item.attributes.content_details.attributes
                                      .resource_url
                                  "
                                  @click.prevent="
                                    downloadPdf(
                                      item.attributes.content_details.attributes
                                        .resource_url,
                                      'Suggested_Content'
                                    )
                                  "
                                >
                                  <span class="truncate">{{
                                    item.attributes.content_details.attributes
                                      .resource_file_name
                                  }}</span>
                                </a>
                                <!-- <a href="#"><span class="truncate">Business</span><span>.doc</span></a> -->
                              </div>
                            </div>
                          </div>
                          <div
                            class="suggestion-container"
                            v-if="
                              item.attributes.content_details.type ==
                              'video_learn_obj'
                            "
                          >
                            <h5>Suggested Content(video)</h5>
                            <div class="suggestion-row">
                              <div class="suggestion-video">
                                <a
                                  @click="
                                    $modal.show('suggestedContentModal', {
                                      data: item.attributes.content_details
                                        .attributes.global_video.attributes
                                        .wistia_code,
                                      type: 'video',
                                      title: item.attributes,
                                    })
                                  "
                                >
                                  <img
                                    :src="
                                      item.attributes.content_details.attributes
                                        .global_video.attributes
                                        .wistia_thumbnail_url
                                    "
                                    alt="suggestion"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- feedback video sendtart -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>

            <modal
              name="iterationInfo"
              width="560"
              height="auto"
              :scrollable="true"
            >
              <div class="modal-content modal-info-content">
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('iterationInfo')"
                >
                  <em class="icon-cross"></em>
                </button>
                <div class="modal-body" v-if="emailfeedbackResponse.attributes">
                  <h3>What is an iteration?</h3>
                  {{ emailfeedbackResponse.attributes.iteration_explanation }}
                </div>
              </div>
            </modal>

            <modal
              name="iterationInfoShow"
              width="560"
              height="auto"
              :scrollable="true"
            >
              <div class="modal-content modal-info-content">
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('iterationInfoShow')"
                >
                  <em class="icon-cross"></em>
                </button>
                <div class="modal-body" v-if="emailfeedbackResponse.attributes">
                  <h3>What is an iteration?</h3>
                  {{ emailfeedbackResponse.attributes.iteration_explanation }}
                </div>
              </div>
            </modal>

            <!-- finalFeedback Task modal end -->
            <modal
              name="feedbackInfo"
              width="560"
              height="auto"
              :scrollable="true"
            >
              <div class="modal-content modal-info-content">
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('feedbackInfo')"
                >
                  <em class="icon-cross"></em>
                </button>
                <div class="modal-body" v-if="infoModalValues.attributes">
                  <h3>How {{ skillName }} is Assessed.</h3>
                  <p
                    v-if="
                      infoModalValues.attributes.email_skill.eval_explanation !=
                      null
                    "
                  >
                    {{
                      infoModalValues.attributes.email_skill.eval_explanation
                    }}
                  </p>
                  <p
                    v-if="
                      infoModalValues.attributes.email_skill.eval_explanation ==
                        null &&
                      infoModalValues.attributes.global_skill
                        .eval_explanation != null
                    "
                  >
                    {{
                      infoModalValues.attributes.global_skill.eval_explanation
                    }}
                  </p>
                </div>
              </div>
            </modal>

            <modal
              name="gatheringInformation"
              :width="815"
              height="auto"
              :scrollable="true"
            >
              <div
                class="modal-content modal-gathering-content"
                v-if="interstitialContents.length > 0"
              >
                <div class="modal-body">
                  <div class="modal-gather-content">
                    <div
                      class="gather-content-item"
                      v-if="
                        this.selectedInterstitialContent.attributes
                          .content_details.type == 'text_learn_obj'
                      "
                    >
                      <h3
                        v-html="
                          this.selectedInterstitialContent.attributes.title
                        "
                      ></h3>
                      <p
                        v-html="
                          this.selectedInterstitialContent.attributes
                            .description
                        "
                      ></p>
                      <p
                        v-html="
                          this.selectedInterstitialContent.attributes
                            .content_details.attributes.title
                        "
                      ></p>
                    </div>
                    <div
                      class="gather-content-item"
                      v-if="
                        selectedInterstitialContent.attributes.content_details
                          .type == 'video_learn_obj'
                      "
                    >
                      <h3>
                        {{ selectedInterstitialContent.attributes.title }}
                      </h3>
                      <p class="mb-4">
                        {{ selectedInterstitialContent.attributes.description }}
                      </p>

                      <div class="introduction-video data-video">
                        <div
                          class="wistia_responsive_wrapper"
                          style="height: 100%; left: 0; top: 0; width: 100%"
                        >
                          <iframe
                            :src="
                              preIframUrl +
                              selectedInterstitialContent.attributes
                                .content_details.attributes.global_video
                                .attributes.wistia_code +
                              postIframUrl
                            "
                            title="Video.mp4"
                            class="wistia_embed"
                            name="wistia_embed"
                            allowtransparency="true"
                            scrolling="no"
                            allowfullscreen
                            mozallowfullscreen
                            webkitallowfullscreen
                            oallowfullscreen
                            msallowfullscreen
                            width="100%"
                            height="100%"
                          ></iframe>
                        </div>
                      </div>
                    </div>

                    <div
                      class="gather-content-item"
                      v-if="
                        this.selectedInterstitialContent.attributes
                          .content_details.type == 'file_learn_obj'
                      "
                    >
                      <h3
                        v-html="
                          this.selectedInterstitialContent.attributes.title
                        "
                      ></h3>
                      <p
                        v-html="
                          this.selectedInterstitialContent.attributes
                            .description
                        "
                      ></p>
                      <div class="suggestion-container">
                        <div class="suggestion-row mt-4">
                          <div class="suggestion-pdf">
                            <a
                              href="selectedInterstitialContent.attributes.content_details.attributes.resource_url"
                              target="_blank"
                            >
                              <span class="truncate">{{
                                this.selectedInterstitialContent.attributes
                                  .content_details.attributes.resource_file_name
                              }}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gather-content-item"
                      v-if="
                        selectedInterstitialContent.attributes.content_details
                          .type == 'slide_learn_obj'
                      "
                    >
                      <h3>
                        {{ selectedInterstitialContent.attributes.title }}
                      </h3>
                      <p class="mb-4">
                        {{ selectedInterstitialContent.attributes.description }}
                      </p>

                      <carousel
                        :margin="0"
                        :nav="true"
                        :navText="[]"
                        :autoplay="false"
                        :autoHeight="false"
                        :items="1"
                        :dots="false"
                        :loop="true"
                      >
                        <div
                          v-for="img in selectedInterstitialContent.attributes
                            .content_details.attributes.slider_images"
                          :key="img.id"
                        >
                          <div class="introduction-video">
                            <div class="introduction-intro-title">
                              {{ img.attributes.caption }}
                            </div>
                            <img
                              v-if="img.attributes.resource_url.large_version"
                              :src="img.attributes.resource_url.large_version"
                              alt="mask"
                              class="intro-slide-video"
                            />
                          </div>
                        </div>
                      </carousel>
                    </div>
                  </div>
                </div>
              </div>
            </modal>
            <div
              v-if="
                loDetails.attributes.learning_object.attributes.card_type ===
                'submission'
              "
            >
              <div class="modal-content" v-if="file.file_type === 'pdf'">
                <div class="modal-body">
                  <div class="modal-header modal-print-header mb-4">
                    <div class="modal-print-row">
                      <div class="modal-print-nav">
                        <div class="data-results">
                          Page {{ page }} of {{ numPages }}
                        </div>
                        <div aria-label="PDF page navigation">
                          <ul class="pagination pdf-pagination">
                            <li class="page-item" v-if="page !== 1">
                              <a
                                class="page-link"
                                aria-label="Previous"
                                @click="page--"
                              >
                                &lt;
                              </a>
                            </li>

                            <li class="page-item" v-if="page < numPages">
                              <a
                                class="page-link"
                                aria-label="Next"
                                @click="page++"
                              >
                                &gt;
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="modal-print-right">
                        <button class="btn-focus loader" @click="rotate += 90">
                          &#x27F3;
                        </button>
                        <button class="btn-focus rotate" @click="rotate -= 90">
                          Rotate
                        </button>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%; align-items: center">
                    <div
                      v-if="loadedRatio > 0 && loadedRatio < 1"
                      style="
                        background-color: green;
                        color: white;
                        text-align: center;
                      "
                      :style="{ width: loadedRatio * 100 + '%' }"
                    >
                      {{ Math.floor(loadedRatio * 100) }}%
                    </div>
                    <pdf
                      ref="pdfRef"
                      :source="file.url"
                      :page="page"
                      :rotate="rotate"
                      @num-pages="numPages = $event"
                      @link-clicked="page = $event"
                      @rendered="handleDocumentRender"
                    ></pdf>
                  </div>
                </div>
              </div>
              <div v-if="file.file_type === 'csv'">
                <div id="example-table"></div>
              </div>
              <div v-if="has_text">
                <div class="learn-reply-head">
                  <span>Learner Text Submission</span>
                </div>
                <div class="learn-object-form learn-editor-height mb-3">
                  <span class="emailBody" v-html="editorText"></span>
                </div>
              </div>
              <div class="messages-notes mt-5" v-if="interimResponse != null">
                <h5>Message from Instructor</h5>
                <p v-html="interimResponse"></p>
              </div>
              <div
                class="learn-btn text-right mt-4"
                v-if="status == 'published'"
              >
                <a
                  href="javascript:void(0);"
                  class="btn primary"
                  @click="showSubmissionFeedback()"
                >
                  {{ "See Feedback" }}
                  <em class="icon-arrow-right"></em>
                </a>
              </div>
            </div>
            <modal
              name="submissionEmailResult"
              class="modal-bottomScreen"
              width="100%"
              height="auto"
              :scrollable="true"
            >
              <div class="modal-content modal-grade-content">
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('submissionEmailResult')"
                >
                  <em class="icon-close"></em>
                </button>
                <div class="modal-body m-auto">
                  <div class="modal-title">
                    <h3>Here is your feedback for last completed Task.</h3>
                    <div class="modal-grade">
                      <p>Grade {{ emailDebriefResult.overall_grade }}%</p>
                    </div>
                  </div>
                  <div class="modal-grade-items">
                    <div
                      class="modal-grade-row"
                      v-for="result in emailDebriefResult.data"
                      :key="result.id"
                    >
                      <div class="modal-grade-icon">
                        <h4>{{ result.attributes.score }}</h4>
                        <span>Score</span>
                      </div>
                      <div>
                        <div class="modal-grade-head">
                          <h4>{{ result.attributes.rubric_criteria }}</h4>
                          <button
                            v-if="result.attributes.rubric_criteria_desc"
                            :content="result.attributes.rubric_criteria_desc"
                            v-tippy="{ trigger: 'click', arrow: true }"
                            class="info-icon no-style-btn"
                          >
                            i
                          </button>
                          <span
                            >(Weight
                            {{ result.attributes.rubric_weight }}%)</span
                          >
                        </div>
                        <p v-html="result.attributes.rubric_feedback"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>
            <div class="learn-steps" v-if="cardType === 'quiz'">
              <div v-for="(question, i) in questions" :key="question.id">
                <div class="learn-object" v-if="i === questionId">
                  <div class="learn-object-progress">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 10%"
                        :style="{
                          width: [((i + 1) * 100) / questions.length + '%'],
                        }"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div class="learn-object-question">
                      Question {{ i + 1 }}/{{ questions.length }}
                    </div>
                  </div>
                  <div class="learn-object-editor">
                    <div class="learn-object-info">
                      <h4 v-html="question.attributes.question"></h4>
                    </div>
                    <div class="learn-object-form learn-voice-form">
                      <div
                        class="learn-voice-textarea"
                        v-if="
                          question.attributes.question_type === 'long_answer'
                        "
                      >
                        <textarea
                          class="form-control"
                          rows="6"
                          v-model="question.answer"
                          @input="quizAnswer(question.answer)"
                        ></textarea>
                      </div>
                      <div
                        class="learn-voice-input"
                        v-if="question.attributes.question_type === 'numeric'"
                      >
                        <input
                          type="number"
                          class="form-control col-12 col-lg-6"
                          placeholder="Add Numeric Value"
                          v-model="question.answer"
                          @input="quizAnswer(question.answer)"
                          :disabled="resultStatus"
                          onpaste="return false;"
                          onkeydown="javascript: return event.keyCode === 69 ? false : true"
                        />
                      </div>
                      <div
                        class="learn-voice-input"
                        v-if="question.attributes.question_type === 'range'"
                      >
                        <input
                          type="number"
                          class="form-control col-12 col-lg-6"
                          placeholder="Add Numeric Value"
                          v-model="question.answer"
                          :disabled="resultStatus"
                          onpaste="return false;"
                          @input="quizAnswer(question.answer)"
                          onkeydown="javascript: return event.keyCode === 69 ? false : true"
                        />
                      </div>
                      <div
                        class="learn-voice-checkboxes"
                        v-if="
                          question.attributes.question_type ===
                          'multiple_choice'
                        "
                      >
                        <div class="form-checkboxes d-block">
                          <div
                            class="form-checkboxes-items"
                            v-for="option in question.attributes.mcq_options"
                            :key="option.id"
                          >
                            <label class="custom-checkbox">
                              {{ option.attributes.option }}
                              <input
                                type="radio"
                                :disabled="resultStatus"
                                v-model="question.mcqStatus"
                                @change="
                                  selectMcqOption(i, question, option.id)
                                "
                                :checked="option.id === question.answer"
                                :value="option.id"
                              />
                              <span class="checkmark circle"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="learn-object-pagination">
                      <button
                        class="btn default"
                        :disabled="resultStatus"
                        v-if="i > 0 && !resultStatus"
                        @click="prevQuestion(i)"
                      >
                        Previous
                      </button>
                      <button
                        class="btn primary ml-auto"
                        :disabled="disableNextBtn"
                        @click="nextQuestion(i)"
                        v-if="i !== questions.length - 1"
                      >
                        Next
                      </button>
                      <a
                        href="javascript:void(0);"
                        v-if="i === questions.length - 1"
                        @click="getResult"
                        class="btn primary ml-auto"
                        >See Result</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <modal
              name="seeResult"
              class="modal-bottomScreen"
              width="100%"
              height="auto"
              :scrollable="true"
            >
              <div class="modal-content modal-score-content">
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('seeResult')"
                >
                  <em class="icon-close"></em>
                </button>

                <div class="modal-body">
                  <div class="modal-score-status">{{ tallyMessage }}</div>
                  <h3 class="w-100">{{ overallMessage }}</h3>
                  <div
                    class="modal-score-strip"
                    v-if="overAllAssesment && overAllAssesment.display_feedback"
                  >
                    <span class="modal-score-icon">
                      <img
                        v-if="overAllAssesment.assessment_icon_url"
                        :src="overAllAssesment.assessment_icon_url"
                        alt="developing"
                      />
                      {{ overAllAssesment.assessment_label }}
                    </span>
                    <p
                      v-if="overAllAssesment.feedback"
                      v-html="overAllAssesment.feedback"
                    ></p>
                  </div>
                  <div class="modal-score-lists" v-if="results.length > 0">
                    <div
                      class="modal-score-items"
                      v-for="(result, i) in results"
                      :key="result.id"
                    >
                      <div class="row">
                        <div class="col-12 col-lg-6">
                          <div class="modal-score-question">
                            <div class="modal-score-title">
                              Question {{ i + 1 }}
                            </div>
                            <p
                              v-html="
                                result.attributes.question.attributes.question
                              "
                            ></p>
                          </div>
                          <div
                            class="modal-score-question"
                            v-if="
                              result.attributes.question.attributes
                                .question_type != 'multiple_choice'
                            "
                          >
                            <div class="modal-score-title">Your Answer</div>
                            <p>{{ result.attributes.learner_answer }}</p>
                          </div>
                          <div
                            class="modal-score-question"
                            v-if="
                              result.attributes.question.attributes
                                .question_type == 'multiple_choice'
                            "
                          >
                            <div class="modal-score-title">Your Answer</div>
                            <p>
                              {{
                                result.attributes.learner_chosed_option
                                  .attributes.option
                              }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="col-12 col-lg-6"
                          v-if="result.attributes.is_correct"
                        >
                          <div class="modal-score-anwser">
                            <img
                              src="../assets/images/tick-icon.svg"
                              alt="tick"
                            />
                            <span>That's correct.</span>
                          </div>
                          <div class="modal-score-placheolder">
                            <!-- Correct Answer -->
                            <p
                              class="feedback_tag"
                              v-html="result.attributes.feedback_received"
                            ></p>
                          </div>
                        </div>
                        <div
                          class="col-12 col-lg-6"
                          v-if="!result.attributes.is_correct"
                        >
                          <div class="modal-score-anwser">
                            <img
                              src="../assets/images/cross-icon.svg"
                              alt="cross"
                            />
                            <span>That’s incorrect.</span>
                          </div>
                          <div
                            v-if="
                              result.attributes.question.attributes
                                .question_type == 'numeric'
                            "
                            class="modal-score-placheolder"
                          >
                            Correct answer is
                            {{
                              result.attributes.question.attributes
                                .numeric_answer.attributes.answer
                            }}
                            <p
                              class="feedback_tag"
                              v-html="result.attributes.feedback_received"
                            ></p>
                          </div>
                          <div
                            v-if="
                              result.attributes.question.attributes
                                .question_type == 'multiple_choice'
                            "
                            class="modal-score-placheolder"
                          >
                            Correct answer is
                            {{
                              result.attributes.correct_option[0].attributes
                                .option
                            }}
                            <p
                              class="feedback_tag"
                              v-html="result.attributes.feedback_received"
                            ></p>
                          </div>
                          <div
                            v-if="
                              result.attributes.question.attributes
                                .question_type == 'long_answer'
                            "
                            class="modal-score-placheolder"
                          >
                            <!-- Correct answer is {{result.attributes.correct_option[0].attributes.option}} -->
                            <p
                              class="feedback_tag"
                              v-html="result.attributes.feedback_received"
                            ></p>
                          </div>
                          <div
                            v-if="
                              result.attributes.question.attributes
                                .question_type == 'range'
                            "
                            class="modal-score-placheolder"
                          >
                            Correct answer range from
                            {{
                              result.attributes.question.attributes.range_answer
                                .attributes.low_range
                            }}
                            to
                            {{
                              result.attributes.question.attributes.range_answer
                                .attributes.high_range
                            }}
                            <p
                              class="feedback_tag"
                              v-html="result.attributes.feedback_received"
                            ></p>
                          </div>
                        </div>
                        <div v-if="!result.attributes.is_correct">
                          <div
                            class="col-12"
                            v-for="item in result.attributes.question.attributes
                              .adaptive_contents"
                            :key="item.id"
                          >
                            <div
                              class="suggestion-container"
                              v-if="
                                item.attributes.content_details.type ==
                                'slide_learn_obj'
                              "
                            >
                              <h5>Suggested Content</h5>
                              <div class="suggestion-row">
                                <div class="suggestion-slide">
                                  <a
                                    @click="
                                      $modal.show('suggestedContentModal', {
                                        data: item.attributes.content_details
                                          .attributes.slider_images,
                                        type: 'slide',
                                        title: item.attributes,
                                      })
                                    "
                                  >
                                    <img
                                      :src="
                                        item.attributes.content_details
                                          .attributes.slider_images[0]
                                          .attributes.resource_url.thumbnail
                                      "
                                      alt="suggestion"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              class="suggestion-container"
                              v-if="
                                item.attributes.content_details.type ==
                                'text_learn_obj'
                              "
                            >
                              <h5>Suggested Content(text)</h5>
                              <div class="suggestion-row">
                                <div
                                  class="suggestion-slide"
                                  v-html="
                                    item.attributes.content_details.attributes
                                      .title
                                  "
                                ></div>
                              </div>
                            </div>
                            <!-- suggestion container pdf  -->
                            <div
                              class="suggestion-container"
                              v-if="
                                item.attributes.content_details.type ==
                                'file_learn_obj'
                              "
                            >
                              <h5>Suggested Content(file)</h5>
                              <div class="suggestion-row">
                                <div class="suggestion-pdf">
                                  <a
                                    @click="
                                      $modal.show('pdfModal', {
                                        pdfUrl:
                                          item.attributes.content_details
                                            .attributes.resource_url,
                                        lable:
                                          item.attributes.content_details
                                            .attributes.resource_file_name,
                                      })
                                    "
                                  >
                                    <span class="truncate">{{
                                      item.attributes.content_details.attributes
                                        .resource_file_name
                                    }}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              class="suggestion-container"
                              v-if="
                                item.attributes.content_details.type ==
                                'video_learn_obj'
                              "
                            >
                              <h5>Suggested Content(video)</h5>
                              <div class="suggestion-row">
                                <div class="suggestion-video">
                                  <a
                                    @click="
                                      $modal.show('suggestedContentModal', {
                                        data: item.attributes.content_details
                                          .attributes.global_video.attributes
                                          .wistia_code,
                                        type: 'video',
                                        title: item.attributes,
                                      })
                                    "
                                  >
                                    <img
                                      :src="
                                        item.attributes.content_details
                                          .attributes.global_video.attributes
                                          .wistia_thumbnail_url
                                      "
                                      alt="suggestion"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>
            <div v-if="cardType === 'dialogic'">
              <div class="learn-object">
                <div class="learn-object-head">
                  <div class="learn-object-col">
                    <div class="learn-object-label">Your Mentor</div>
                    <div class="learn-object-user">
                      <img
                        :src="
                          loDetails.attributes.learning_object.attributes
                            .card_detail.attributes.character.attributes
                            .char_photo_url.thumbnail
                        "
                        v-if="
                          loDetails.attributes.learning_object.attributes
                            .card_detail.attributes.character.attributes
                            .char_photo_url.thumbnail
                        "
                        alt="image"
                      />
                    </div>
                    <div class="learn-object-titles">
                      <h5>
                        {{
                          loDetails.attributes.learning_object.attributes
                            .card_detail.attributes.character.attributes
                            .char_full_name
                        }}
                      </h5>
                      <p>
                        {{
                          loDetails.attributes.learning_object.attributes
                            .card_detail.attributes.character.attributes
                            .world_role
                        }}({{
                          loDetails.attributes.learning_object.attributes
                            .card_detail.attributes.character.attributes
                            .org_name
                        }})
                      </p>
                    </div>
                  </div>
                  <!-- learn object-editor end-->
                </div>
                <div class="learn-object-preview" v-if="submitquestions.length">
                  <!-- learn object-editor start-->
                  <div
                    class="learn-object-info"
                    v-for="(que, k) in submitquestions"
                    :key="k"
                  >
                    <p class="dialogic-question" v-if="k === 0">
                      {{
                        replaceLearnerToken(
                          loDetails.attributes.learning_object.attributes
                            .card_detail.attributes.introduction
                        )
                      }}
                    </p>
                    <div
                      class="dialogic-question"
                      v-if="que.attributes.attempt === 1"
                    >
                      <p
                        v-html="replaceLearnerToken(que.attributes.question)"
                      ></p>
                    </div>
                    <p
                      class="learner-answer"
                      v-html="replaceLearnerToken(que.attributes.answer)"
                    ></p>
                    <div
                      class="dialogic-question"
                      v-if="que.attributes.character_response"
                    >
                      <p
                        v-html="
                          replaceLearnerToken(que.attributes.character_response)
                        "
                      ></p>
                    </div>
                    <p
                      class="dialogic-question"
                      v-if="que.attributes.follow_up_question"
                    >
                      {{ que.attributes.follow_up_question }}
                    </p>
                  </div>
                </div>
                <div
                  class="learn-object-editor"
                  v-if="
                    questions_datas.length > 0 ||
                    (submitquestions[submitquestions.length - 1] &&
                      submitquestions[submitquestions.length - 1].attributes
                        .follow_up_question)
                  "
                >
                  <div class="learn-object-info">
                    <h4 v-if="submitquestions.length === 0">
                      {{
                        replaceLearnerToken(
                          loDetails.attributes.learning_object.attributes
                            .card_detail.attributes.introduction
                        )
                      }}
                    </h4>
                    <h4
                      v-if="
                        submitquestions.length > 0 &&
                        submitquestions[submitquestions.length - 1].attributes
                          .follow_up_question
                      "
                    ></h4>
                    <h4
                      v-else
                      v-html="questions_datas[0].attributes.question"
                    ></h4>
                  </div>
                  <div
                    class="learn-object-form"
                    v-if="
                      questions_datas.length > 0 ||
                      (submitquestions[submitquestions.length - 1] &&
                        submitquestions[submitquestions.length - 1].attributes
                          .follow_up_question)
                    "
                  >
                    <vue-editor v-model="ans"></vue-editor>
                  </div>
                  <div v-else class="learn-object-info">
                    <h4
                      v-if="
                        questions_datas.length > 0 ||
                        (submitquestions[submitquestions.length - 1] &&
                          !submitquestions[submitquestions.length - 1]
                            .attributes.follow_up_question)
                      "
                    >
                      {{ card_data.card_detail.attributes.conclusion }}
                    </h4>
                  </div>
                </div>
                <div
                  class="learn-btn text-right mt-4"
                  v-if="questions_datas.length === 0"
                >
                  <button
                    v-if="questions_datas.length === 0"
                    class="btn primary"
                    @click="dialogicEvaluations()"
                  >
                    See Feedback
                    <em class="icon-arrow-right"></em>
                  </button>
                </div>
              </div>
            </div>
            <modal
              name="dialogicLearningData"
              :adaptive="true"
              class="modal-bottomScreen"
              width="100%"
              height="auto"
              :scrollable="true"
            >
              <div
                class="modal-content modal-score-content modal-dialogic-content"
              >
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('dialogicLearningData')"
                >
                  <em class="icon-close"></em>
                </button>
                <div class="modal-body">
                  <div class="modal-title modal-flex-title">
                    <h3>
                      Feedback for
                      {{ loDetails.attributes.learning_object.attributes.name }}
                    </h3>
                  </div>
                  <div
                    class="modal-score-strip mb-4"
                    v-if="
                      overall_assmnt_item &&
                      overall_assmnt_item.display_feedback
                    "
                  >
                    <span class="modal-score-icon" v-if="!hideAssessmentLabel">
                      <img
                        v-if="overall_assmnt_item.assessment_icon_url"
                        :src="overall_assmnt_item.assessment_icon_url"
                        :alt="overall_assmnt_item.assessment_label"
                      />
                      {{ overall_assmnt_item.assessment_label }}
                    </span>
                    <p v-html="overall_assmnt_item.feedback"></p>
                  </div>
                  <!-- carousel container repeat -->
                  <div v-if="dialogic_debrief_evaluation">
                    <div
                      class="dialogic-container"
                      v-for="(dialogicdata, key) in dialogic_debrief_evaluation"
                      :key="key"
                    >
                      <div class="dialogic-heading">
                        {{
                          dialogic_debrief_evaluation[key][0].attributes &&
                          dialogic_debrief_evaluation[key][0].attributes
                            .question_concept
                            ? dialogic_debrief_evaluation[key][0].attributes
                                .question_concept
                            : ""
                        }}
                        <button
                          v-if="
                            dialogic_debrief_evaluation[key][0].attributes &&
                            dialogic_debrief_evaluation[key][0].attributes
                              .concept_info
                          "
                          :content="
                            dialogic_debrief_evaluation[key][0].attributes
                              .concept_info
                          "
                          v-tippy="{ trigger: 'click', arrow: true }"
                          class="display-inline info-icon no-style-btn"
                        >
                          i
                        </button>
                      </div>
                      <carousel
                        class="dialogic-carousel"
                        :margin="30"
                        :autoplay="false"
                        :rewind="true"
                        :nav="true"
                        :responsive="{
                          0: { items: 1 },
                          768: { items: 2 },
                          1024: { items: 3 },
                          1367: { items: 3 },
                        }"
                        :loop="false"
                        :dots="false"
                        :id="dialogic_debrief_evaluation[key]"
                      >
                        <!-- feedback items start -->
                        <div
                          v-for="dialogic in dialogic_debrief_evaluation[key]"
                          :key="dialogic.id"
                        >
                          <!-- feedback items end -->
                          <!-- feedback items start -->
                          <div
                            class="feedback-items missed-items"
                            v-if="dialogic.attributes.key_topic_missed"
                          >
                            <div class="feedback-content">
                              <div class="feedback-heads">
                                <div
                                  class="feedback-icon"
                                  v-if="!hideAssessmentLabel"
                                ></div>
                                <div class="feedback-infos">
                                  <div
                                    class="feedback-head"
                                    v-if="!hideAssessmentLabel"
                                  >
                                    Missed
                                  </div>
                                  <h4>{{ dialogic.attributes.topic_label }}</h4>
                                </div>
                              </div>
                              <p
                                v-html="dialogic.attributes.debrief_received"
                              ></p>
                            </div>
                          </div>
                          <div class="feedback-items mastery-items" v-else>
                            <div class="feedback-content">
                              <div class="feedback-heads">
                                <div
                                  class="feedback-icon"
                                  v-if="!hideAssessmentLabel"
                                >
                                  <img
                                    :src="
                                      dialogic.attributes.assessment_icon_url
                                    "
                                    :alt="dialogic.attributes.assessment_label"
                                  />
                                </div>
                                <div class="feedback-infos">
                                  <div
                                    v-if="!hideAssessmentLabel"
                                    class="feedback-head"
                                  >
                                    {{ dialogic.attributes.assessment_label }}
                                  </div>
                                  <h4>{{ dialogic.attributes.topic_label }}</h4>
                                </div>
                              </div>
                              <p
                                v-html="dialogic.attributes.debrief_received"
                              ></p>
                            </div>
                          </div>
                          <div v-if="dialogic.attributes.suggested_contents">
                            <div
                              class="col-12 px-0"
                              v-for="item in dialogic.attributes
                                .suggested_contents"
                              :key="item.id"
                            >
                              <!-- suggestion container slide  -->
                              <div
                                class="suggestion-container"
                                v-if="
                                  item.attributes.content_details.type ==
                                  'slide_learn_obj'
                                "
                              >
                                <h5>Suggested Content</h5>
                                <div class="suggestion-row">
                                  <div class="suggestion-slide">
                                    <a
                                      @click="
                                        $modal.show('adaptiveModal1', {
                                          data: item.attributes.content_details
                                            .attributes.slider_images,
                                          title: item.attributes,
                                          type: 'slide',
                                        })
                                      "
                                    >
                                      <img
                                        :src="
                                          item.attributes.content_details
                                            .attributes.slider_images[0]
                                            .attributes.resource_url.thumbnail
                                        "
                                        alt="suggestion"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="suggestion-container"
                                v-if="
                                  item.attributes.content_details.type ==
                                  'text_learn_obj'
                                "
                              >
                                <h5>Suggested Content(text)</h5>
                                <div class="suggestion-row">
                                  <div
                                    class="suggestion-slide"
                                    v-html="
                                      item.attributes.content_details.attributes
                                        .title
                                    "
                                  ></div>
                                </div>
                              </div>
                              <!-- suggestion container pdf  -->
                              <div
                                class="suggestion-container"
                                v-if="
                                  item.attributes.content_details.type ==
                                  'file_learn_obj'
                                "
                              >
                                <h5>Suggested Content(file)</h5>
                                <div class="suggestion-row">
                                  <div class="suggestion-pdf">
                                    <a
                                      @click="
                                        $modal.show('pdfModal', {
                                          pdfUrl:
                                            item.attributes.content_details
                                              .attributes.resource_url,
                                          lable:
                                            item.attributes.content_details
                                              .attributes.resource_file_name,
                                        })
                                      "
                                    >
                                      <span class="truncate">{{
                                        item.attributes.content_details
                                          .attributes.resource_file_name
                                      }}</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <!-- suggestion container video  -->
                              <div
                                class="suggestion-container"
                                v-if="
                                  item.attributes.content_details.type ==
                                  'video_learn_obj'
                                "
                              >
                                <h5>Suggested Content(video)</h5>
                                <div class="suggestion-row">
                                  <div class="suggestion-video">
                                    <a
                                      @click="
                                        $modal.show('adaptiveModal1', {
                                          data: item.attributes.content_details
                                            .attributes.global_video.attributes
                                            .wistia_code,
                                          title: item.attributes,
                                          type: 'video',
                                        })
                                      "
                                    >
                                      <img
                                        :src="
                                          item.attributes.content_details
                                            .attributes.global_video.attributes
                                            .wistia_thumbnail_url
                                        "
                                        alt="suggestion"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- feedback items end -->
                        </div>
                        <!-- feedback items end -->
                      </carousel>
                    </div>
                  </div>
                </div>
              </div>
            </modal>
            <div v-if="cardType === 'chat'">
              <div class="learn-object learn-chat">
                <div class="learn-object-head">
                  <div class="learn-object-col">
                    <div class="learn-object-user">
                      <div class="learn-object-user" v-if="chat_character">
                        <img
                          :src="
                            chat_character.attributes.char_photo_url.thumbnail
                          "
                          v-if="
                            chat_character.attributes.char_photo_url.thumbnail
                          "
                          alt="image"
                        />
                      </div>
                    </div>
                    <div class="learn-object-titles" v-if="chat_character">
                      <h5>{{ chat_character.attributes.char_full_name }}</h5>
                      <p>
                        {{ chat_character.attributes.world_role }}({{
                          chat_character.attributes.org_name
                        }})
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="learn-reply-head"
                  if="userData && userData.created_at"
                >
                  <span class="learn-reply-date">{{
                    userData.created_at
                  }}</span>
                </div>
                <div class="learn-chat-container" v-if="chatMess.length > 0">
                  <!-- learner chat me  -->
                  <div
                    v-for="msg in chatMess"
                    :key="msg.id"
                    :class="
                      !msg.attributes.assistant_response
                        ? 'learn-chat-me'
                        : 'learn-chat-user'
                    "
                  >
                    <div class="learn-chat-box">
                      <div
                        class="learn-chat-image"
                        v-if="msg.attributes.assistant_response"
                      >
                        <div v-if="chat_character">
                          <img
                            :src="
                              chat_character.attributes.char_photo_url.thumbnail
                            "
                            v-if="
                              chat_character &&
                              chat_character.attributes.char_photo_url &&
                              chat_character.attributes.char_photo_url.thumbnail
                            "
                            alt="image"
                          />
                        </div>
                      </div>
                      <div class="learn-chat-msg">
                        {{ msg.attributes.message }}
                      </div>
                      <div
                        class="learn-chat-image"
                        v-if="!msg.attributes.assistant_response"
                      >
                        <div v-if="chat_character">
                          <img
                            :src="
                              userDetail.attributes.char_photo_url.thumbnail
                            "
                            v-if="
                              userDetail.attributes.char_photo_url &&
                              userDetail.attributes.char_photo_url.thumbnail
                            "
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="learn-chat-time">
                      {{ msg.attributes.time_ago }}
                    </div>
                  </div>
                  <div class="learn-btn text-right mt-5" v-if="isChatEnd">
                    <a
                      href="javascript:void(0);"
                      class="btn secondary"
                      @click="ChatEvaluations"
                    >
                      {{ "See Feedback" }}
                      <em class="icon-arrow-right"></em>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="cardType === 'submission_email'">
              <div v-if="emailData.length > 0">
                <div class="learn-object">
                  <div v-for="(data, key) in emailData" :key="data.id">
                    <div class="learn-object-forward" v-if="key === 0">
                      <div class="learn-object-tag">
                        {{ data.attributes.email_subject }}
                      </div>
                      <div class="learn-object-head">
                        <div
                          class="learn-object-col"
                          v-for="character in toCharacters"
                          :key="'toCharacter' + character.id"
                        >
                          <div class="learn-object-user">
                            <img
                              :src="
                                character.attributes.char_photo_url.thumbnail
                              "
                              v-if="
                                character.attributes.char_photo_url.thumbnail
                              "
                              alt="image"
                            />
                          </div>
                          <div class="learn-object-titles">
                            <h5>{{ character.attributes.char_full_name }}</h5>
                            <p>From {{ userDetails.full_name }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="learn-object-paragraph">
                        <div class="learn-object-desc">
                          <p
                            v-html="
                              replaceLearnerToken(data.attributes.email_body)
                            "
                          ></p>
                        </div>
                      </div>
                    </div>
                    <div class="learn-object-reply" v-if="key !== 0">
                      <div class="learn-reply-head">
                        <span>Reply from {{ userDetails.full_name }}</span>
                      </div>
                      <div
                        class="learn-object-tag"
                        v-if="data.attributes.email_subject"
                      >
                        Reply: {{ data.attributes.email_subject }}
                      </div>
                      <div class="learn-object-head">
                        <div class="learn-object-col">
                          <div class="learn-object-user"></div>
                          <div class="learn-object-titles">
                            <h5>
                              {{ toCharacters[0].attributes.char_full_name }}
                            </h5>
                            <p>To: {{ userDetails.full_name }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="learn-object-paragraph">
                        <div class="learn-object-desc">
                          <p
                            v-html="
                              replaceLearnerToken(data.attributes.email_body)
                            "
                          ></p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="learn-object-reply"
                      v-if="data.attributes.interim_response"
                    >
                      <div class="learn-reply-head">
                        <span
                          >Reply from
                          {{
                            data.attributes.interim_response
                              ? toCharacters[0].attributes.char_full_name
                              : userDetails.full_name
                          }}</span
                        >
                      </div>
                      <div
                        class="learn-object-tag"
                        v-if="data.attributes.email_subject"
                      >
                        Reply: {{ data.attributes.email_subject }}
                      </div>
                      <div class="learn-object-head">
                        <div class="learn-object-col">
                          <div class="learn-object-user"></div>
                          <div class="learn-object-titles">
                            <h5>
                              {{
                                data.attributes.interim_response
                                  ? userDetails.full_name
                                  : toCharacters[0].attributes.char_full_name
                              }}
                            </h5>
                            <p>
                              From
                              {{
                                data.attributes.interim_response
                                  ? toCharacters[0].attributes.char_full_name
                                  : userDetails.full_name
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="learn-object-paragraph">
                        <div class="learn-object-desc">
                          <p
                            v-html="
                              replaceLearnerToken(
                                data.attributes.interim_response
                              )
                            "
                          ></p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="learn-object-reply"
                      v-if="
                        data.attributes.email_submission_response &&
                        data.attributes.email_submission_response.attributes &&
                        data.attributes.email_submission_response.attributes
                          .response
                      "
                    >
                      <div class="learn-reply-head">
                        <span
                          >Reply from
                          {{
                            data.attributes.interim_response
                              ? toCharacters[0].attributes.char_full_name
                              : userDetails.full_name
                          }}</span
                        >
                      </div>
                      <div
                        class="learn-object-tag"
                        v-if="data.attributes.email_subject"
                      >
                        Reply: {{ data.attributes.email_subject }}
                      </div>
                      <div class="learn-object-head">
                        <div class="learn-object-col">
                          <div class="learn-object-user"></div>
                          <div class="learn-object-titles">
                            <h5>{{ userDetails.full_name }}</h5>
                            <p>
                              From:
                              {{ toCharacters[0].attributes.char_full_name }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="learn-object-paragraph">
                        <div class="learn-object-desc">
                          <p
                            v-html="
                              replaceLearnerToken(
                                data.attributes.email_submission_response
                                  .attributes.response
                              )
                            "
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="learn-object-reply learn-object-editable"
                    v-if="resubmit"
                  >
                    <div class="learn-reply-head">
                      <span>Reply from {{ userDetails.full_name }}</span>
                    </div>
                    <div class="learn-object-head">
                      <div class="learn-object-col">
                        <div class="learn-object-user"></div>
                        <div class="learn-object-titles">
                          <h5>
                            {{ userDetails.full_name }}
                          </h5>
                          <p>
                            To: {{ toCharacters[0].attributes.char_full_name }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="learn-object-editor">
                      <div class="form-group">
                        <vue-editor
                          :editorToolbar="fullToolbar"
                          v-model="emailBody"
                        ></vue-editor>
                      </div>
                      <div class="learn-object-send">
                        <a
                          href="javascript:void(0);"
                          :class="`btn primary ${emailBody ? '' : 'disabled'}`"
                        >
                          Submit</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="learn-btn text-right mt-4"
                  v-if="status == 'published'"
                >
                  <a
                    href="javascript:void(0);"
                    class="btn primary"
                    @click="showEmailSubmissionFeedback()"
                  >
                    {{ "See Feedback" }}
                  </a>
                </div>
              </div>
            </div>
            <modal
              name="feedbackTask"
              :adaptive="true"
              class="modal-bottomScreen"
              width="100%"
              height="auto"
              :scrollable="true"
            >
              <div class="modal-content modal-feedback-content">
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('feedbackTask')"
                >
                  <em class="icon-close"></em>
                </button>
                <div class="modal-body">
                  <div class="modal-title modal-flex-title">
                    <h3>Here is your feedback for last completed Task.</h3>
                  </div>
                  <div
                    class="modal-score-strip"
                    v-if="
                      overall_assmnt_item &&
                      overall_assmnt_item.display_feedback
                    "
                  >
                    <span class="modal-score-icon">
                      <img
                        :src="url + overall_assmnt_item.assessment_icon_url"
                        alt="developing"
                      />
                      {{ overall_assmnt_item.assessment_label }}
                    </span>
                    <p v-html="overall_assmnt_item.feedback"></p>
                  </div>
                  <!-- feedback items start -->
                  <div v-if="evaluateChatData && evaluateChatData.attributes">
                    <div
                      class="feedback-items missed-items"
                      v-for="chatDebrief in evaluateChatData.attributes
                        .chat_debrief_evaluations"
                      :key="chatDebrief.id"
                    >
                      <div class="feedback-icon"></div>
                      <div class="feedback-content">
                        <div class="feedback-head">
                          {{
                            chatDebrief.attributes.chat_skill_assmnt_missed
                              ? "MISSED"
                              : chatDebrief.attributes.assessment_label
                          }}
                        </div>
                        <h4>
                          {{ chatDebrief.attributes.chat_skill_name }}
                        </h4>
                        <p v-html="chatDebrief.attributes.debrief_received"></p>
                        <div
                          v-if="evaluateChatData.attributes.suggested_contents"
                        >
                          <div
                            class="col-12"
                            v-for="item in evaluateChatData.attributes
                              .suggested_contents"
                            :key="item.id"
                          >
                            <!-- suggestion container slide  -->
                            <div
                              class="suggestion-container"
                              v-if="
                                item.attributes.content_details.type ==
                                'slide_learn_obj'
                              "
                            >
                              <h5>Suggested Content</h5>
                              <div class="suggestion-row">
                                <div class="suggestion-slide">
                                  <a
                                    @click="
                                      $modal.show('adaptiveChatModal', {
                                        data: item.attributes.content_details
                                          .attributes.slider_images,
                                        type: 'slide',
                                      })
                                    "
                                  >
                                    <img
                                      src="item.attributes.content_details.attributes.slider_images[0].attributes.resource_url.thumbnail"
                                      alt="suggestion"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              class="suggestion-container"
                              v-if="
                                item.attributes.content_details.type ==
                                'text_learn_obj'
                              "
                            >
                              <h5>Suggested Content(text)</h5>
                              <div class="suggestion-row">
                                <div
                                  class="suggestion-slide"
                                  v-html="
                                    item.attributes.content_details.attributes
                                      .title
                                  "
                                ></div>
                              </div>
                            </div>
                            <!-- suggestion container pdf  -->
                            <div
                              class="suggestion-container"
                              v-if="
                                item.attributes.content_details.type ==
                                'file_learn_obj'
                              "
                            >
                              <h5>Suggested Content(file)</h5>
                              <div class="suggestion-row">
                                <div class="suggestion-pdf">
                                  <a
                                    @click="
                                      $modal.show('pdfModal', {
                                        pdfUrl:
                                          item.attributes.content_details
                                            .attributes.resource_url,
                                        lable:
                                          item.attributes.content_details
                                            .attributes.resource_file_name,
                                      })
                                    "
                                  >
                                    <span class="truncate">{{
                                      item.attributes.content_details.attributes
                                        .resource_file_name
                                    }}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!-- suggestion container video  -->
                            <div
                              class="suggestion-container"
                              v-if="
                                item.attributes.content_details.type ==
                                'video_learn_obj'
                              "
                            >
                              <h5>Suggested Content(video)</h5>
                              <div class="suggestion-row">
                                <div class="suggestion-video">
                                  <a
                                    @click="
                                      $modal.show('adaptiveChatModal', {
                                        data: item.attributes.content_details
                                          .attributes.global_video.attributes
                                          .wistia_code,
                                        type: 'video',
                                      })
                                    "
                                  >
                                    <img
                                      :src="
                                        item.attributes.content_details
                                          .attributes.global_video.attributes
                                          .wistia_thumbnail_url
                                      "
                                      alt="suggestion"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>
            <modal
              name="finalFeedback"
              class="modal-bottomScreen"
              width="100%"
              height="auto"
              :scrollable="true"
            >
              <div
                class="modal-content modal-final-content modal-dialogic-content"
              >
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('finalFeedback')"
                >
                  <em class="icon-close"></em>
                </button>
                <div class="modal-body m-auto">
                  <div class="modal-title">
                    <h3>Final Feedback <br />{{ finalDebrief.name }}</h3>
                    <div class="final-feedback-grade">
                      <div class="grade-info">
                        <h3
                          v-if="
                            (finalDebrief.enable_grading &&
                              finalDebrief.overall_grade_display_type ===
                                'percentage') ||
                            (finalDebrief.enable_grading &&
                              finalDebrief.overall_grade_display_type ===
                                'both')
                          "
                        >
                          {{ finalDebrief.overall_grade_percentage }}%
                        </h3>
                        <p
                          v-if="
                            (finalDebrief.enable_grading &&
                              finalDebrief.overall_grade_display_type ===
                                'labels') ||
                            (finalDebrief.enable_grading &&
                              finalDebrief.overall_grade_display_type ===
                                'both')
                          "
                          class="grading-p"
                        >
                          {{ finalDebrief.overall_grade_label }}
                        </p>
                      </div>
                      <a
                        class="btn primary"
                        :href="finalDebrief.evaluation_url"
                        v-if="
                          finalDebrief.evaluation_url &&
                          finalDebrief.evaluation_url != false
                        "
                        @click.prevent="
                          downloadPdf(
                            finalDebrief.evaluation_url,
                            'Final Feedback'
                          )
                        "
                        >Download Report</a
                      >
                    </div>
                    <p
                      class="desc"
                      v-html="finalDebrief.final_debrief_overview"
                    ></p>
                  </div>
                  <finalFeedback :finalDebrief="finalDebrief" />
                </div>
              </div>
            </modal>
            <modal
              name="submissionEmailEvaluation"
              class="modal-bottomScreen"
              width="100%"
              height="auto"
              :scrollable="true"
            >
              <div class="modal-content modal-grade-content">
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('submissionEmailEvaluation')"
                >
                  <em class="icon-close"></em>
                </button>
                <div class="modal-body py-4 px-5">
                  <div class="modal-title">
                    <h3 class="mb-4">
                      Here is your feedback for last completed Task.
                    </h3>
                    <div class="modal-grade">
                      <p>Grade {{ emailDebriefResult.overall_grade }}%</p>
                      <a
                        class="btn primary"
                        v-if="canRepeat"
                        @onclick="resubmitData"
                        >Repeat</a
                      >
                    </div>
                  </div>
                  <div
                    class="messages-notes"
                    v-if="emailDebriefResult.feedback"
                  >
                    <h5>Instructor Personal Feedback</h5>
                    <p v-html="emailDebriefResult.feedback"></p>
                  </div>
                  <div class="modal-grade-items">
                    <div
                      class="modal-grade-row"
                      v-for="result in emailDebriefResult.submission_gradings"
                      :key="result.id"
                    >
                      <div class="modal-grade-icon">
                        <h4>{{ result.attributes.score }}</h4>
                        <span>Score</span>
                      </div>
                      <div>
                        <div class="modal-grade-head">
                          <h4>{{ result.attributes.rubric_criteria }}</h4>
                          <button
                            v-if="result.attributes.rubric_criteria_desc"
                            :content="result.attributes.rubric_criteria_desc"
                            v-tippy="{ trigger: 'click', arrow: true }"
                            class="info-icon no-style-btn"
                          >
                            i
                          </button>
                          <span
                            >(Weight
                            {{ result.attributes.rubric_weight }}%)</span
                          >
                        </div>
                        <p v-html="result.attributes.rubric_feedback"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </modal>
            <llm-dialogic-final-feedback-modal 
              :finalDebrief="finalDebrief"
            />
            <!-- <modal
              name="resetLOConfirmModal"
              class="modal-bottomScreen"
              width="100%"
              height="auto"
              :scrollable="true"
            >
              <div class="modal-content modal-grade-content">
                <button
                  type="button"
                  class="modal-close"
                  @click="$modal.hide('resetLOConfirmModal')"
                >
                  <em class="icon-close"></em>
                </button>
                <div class="modal-body mx-auto">
                  <div class="modal-title">
                    <h3 class="mb-4">
                      Are you sure? This action cannot be reversed.
                    </h3>
                  </div>
                  <div>
                    <h4 class="mb-4">
                      The student's work will be deleted permanently.
                    </h4>
                    <div class="d-flex flex-row justify-content-between">
                      <a class="btn btn-danger" @click="resetTask">
                        Confirm Reset Task
                      </a>
                      <a
                        class="btn primary"
                        @click="$modal.hide('resetLOConfirmModal')"
                        >Close Overlay</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </modal> -->
          </div>
        </div>

        <div class="submission-update" v-if="cardType === 'submission'">
          <p>
            Last submission: <span>{{ this.submissionDate }}</span>
          </p>
        </div>
      </div>
      <div class="main-chats">
        <div class="chat-head" v-if="loDetails && loDetails.attributes">
          Send Message to
          {{
            loDetails.attributes.user_section.attributes.user.attributes
              .first_name
          }}
        </div>
        <div class="chat-content" v-if="chatMessages.length > 0">
          <div v-for="msg in chatMessages" :key="msg.id">
            <div class="chat-starDate" v-if="msg.attributes.created_date">
              <span>{{ msg.attributes.created_date }}</span>
            </div>
            <div
              :class="
                msg.attributes.is_instructor
                  ? 'chat-item'
                  : 'chat-item user-item'
              "
            >
              <div class="chat-box">
                <div class="chat-text">
                  {{ msg.attributes.content }}
                </div>
                <div class="chat-image">
                  <img
                    :src="msg.attributes.sender_image.thumbnail"
                    v-if="msg.attributes.sender_image"
                  />
                </div>
              </div>
              <div class="chat-timer">
                <div
                  :class="
                    msg.attributes.is_saved
                      ? 'chat-time'
                      : 'chat-time chat-unsaved'
                  "
                  @click="savedMessages(msg.id, !msg.attributes.is_saved)"
                >
                  {{ msg.attributes.time_ago }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-footer">
          <div class="chat-form">
            <div class="form-input">
              <textarea
                type="text"
                class="form-control"
                placeholder="Type your Message here"
                v-model="chatMessage"
                v-on:keyup.enter="sendChatMessages"
              ></textarea>
            </div>
            <button
              class="btn primary"
              :disabled="chatMessage.length > 0 ? false : true"
              @click="sendChatMessages"
            >
              Send
            </button>
          </div>
          <div
            class="chat-dropdown note-dropdown"
            v-if="savedChatMessages.length > 0"
          >
            <button
              class="btn dropdown-toggle"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Saved Messages
            </button>
            <div
              class="dropdown-menu dropdown-menu-top"
              aria-labelledby="dropdownMenu2"
            >
              <div class="head">
                <h4>Saved Messages ({{ savedChatMessages.length }})</h4>
                <a href="javascript:void(0);">
                  <em class="icon-close"></em>
                </a>
              </div>
              <div class="content">
                <div class="chat-form">
                  <div class="form-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search Message"
                      v-model="searchMessage"
                      v-on:keyup="filterByMessage"
                    />
                  </div>
                </div>
                <div
                  class="chat-messagesItem"
                  v-for="msg in filterChatMessages"
                  :key="msg.id"
                >
                  <div class="chat-messages" @click="setMessage(msg.content)">
                    {{ msg.content }}
                  </div>
                  <a
                    ><em
                      class="icon-trash"
                      @click="savedMessages(msg.id, !msg.is_saved)"
                    ></em
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <plagiarism-modal />
        </div>
      </div>
    </div>
    <writing-assessment-modal
      :writing_assessment="writing_assessment"
      v-if="loDetails.attributes.writing_assessment"
    />
    <reset-LO-confirm-modal />
  </div>
</template>
<script>
import api from "../services/api";
import { mapState } from 'vuex';
import { utilFunctionService } from "@/utils/utils.service";
import carousel from "vue-owl-carousel";
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { commonConfig } from "@/utils/commonConfig";
import { VueEditor } from "vue2-editor";
import Axios from "axios";
import finalFeedback from "../components/finalFeedback.vue";
import llmDialogicFinalFeedbackModal from "../components/modals/llmDialogicFinalFeedbackModal.vue"
import plagiarismIcon from "../components/plagiarismIcon.vue";
import plagiarismModal from "../components/plagiarismModal.vue";
import moment from "moment";
import learnerCentralSideMenu from "../components/learnerCentralSideMenu.vue";
import writingAssessmentModal from "../components/modals/writingAssessmentModal.vue";
import resetLOConfirmModal from "../components/modals/resetLOConfirmModal.vue";
import llmChat from "../components/llmChat.vue";
import llmDialogic from "../components/llmDialogic.vue";
import llmEmail from "../components/llmEmail.vue";
import Tabulator from "tabulator-tables";

export default {
  name: "learnerCentralLo",
  components: {
    carousel,
    VueEditor,
    pdf,
    finalFeedback,
    learnerCentralSideMenu,
    writingAssessmentModal,
    resetLOConfirmModal,
    plagiarismIcon,
    plagiarismModal,
    llmChat,
    llmDialogic,
    llmDialogicFinalFeedbackModal,
    llmEmail
  },
  computed: mapState({
    userHasBeenGraded: state => state.grading
  }),
  data() {
    return {
      isPlagiarised: false,
      plagiarismAmount: 0,
      questions: [],
      src: {},
      loadedRatio: 0,
      page: 1,
      file: "",
      stickyNote: "",
      numPages: 0,
      rotate: 0,
      has_file_upload: false,
      has_text: false,
      index: 0,
      showNote: false,
      loList: [],
      resultStatus: false,
      questionId: "",
      disableNextBtn: false,
      leftQuizId: "",
      cardType: "",
      interimResponse: null,
      emailDebriefResult: "",
      loDetails: {
        attributes: {
          learning_object: {
            attributes: {
              card_type: ""
            }
          },
          user_section: {
            attributes: {
              user: {
                attributes: {
                  profile_url: ""
                }
              }
            }
          }
        }
      },
      status: "",
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      overallMessage: "",
      tallyMessage: "",
      overAllAssesment: "",
      results: [],
      quizId: "",
      submitquestions: [],
      menuIndex: 0,
      questions_datas: [],
      ans: "",
      evaluation_id: "",
      editorText: "",
      overall_assmnt_item: "",
      dialogic_debrief_evaluation: "",
      user_chat_id: "",
      userData: {},
      msg: "",
      chatMess: [],
      userDetail: {},
      chat_character: {},
      mentor_character: {},
      evaluateChatData: {},
      isChatEnd: false,
      finalDebrief: {},
      finalFeedbackData: {},
      resp_id: "",
      toCharacters: "",
      ccCharacters: "",
      email_submission_response: "",
      emailData: [],
      userDetails: {},
      canRepeat: false,
      resubmit: false,
      myWindow: "",
      chatMessages: [],
      chatMessage: "",
      savedChatMessages: [],
      filterChatMessages: [],
      messageDate: "",
      searchMessage: "",
      interstitialContents: [],
      selectedInterstitialContent: {},
      emailDesc: "",
      emailSubject: "",
      nextIteration: false,
      ootoResponseStatus: false,
      ootoCharacters: [],
      ootoNotice: "",
      showInterstitial: false,
      emailSendBtnText: "Send",
      filterEmailIndex: {
        0: { msg: "", arr: [], sub: "" },
        1: { msg: "", arr: [], sub: "" },
        2: { msg: "", arr: [], sub: "" },
        3: { msg: "", arr: [], sub: "" },
        4: { msg: "", arr: [], sub: "" },
      },
      emailLists: [],
      interactiveEvaluationId: "",
      emailfeedbackResponse: "",
      fullToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      showInstructorMsg: false,
      hideAssessmentLabel: false,
      skillName: "",
      infoModalValues: {},
      introEmailStatus: false,
      introEmailSubject: "",
      introEmailBody: "",
      introAttachment: "",
      pdfs: [],
      submissionDate: "",
      isResetLo: false,
      refreshKey: 0,
      writing_assessment: {},
      assessed_sentences: {},
      loType: "",
      showDelete: "Add Note",
      tabulator: null,
      tableData: [],
      containsLLM: false,
      isMoatCustom: false,
      feedbackLoading: false,
    };
  },
  channels: {
    AssessmentResultChannel: {
      connected() {
        console.log("Assessment Result in Learning connected assessment");
      },
      received(res) {
        console.log(this.feedbackLoading);
        if (!this.isMoatCustom && this.feedbackLoading) {
          console.log("Assessment Result in Learning received assessment");
          let existingFinalDebriefIds = this.finalDebrief.data.map(el => el.lo_id);
          if (!existingFinalDebriefIds.includes(res.data.lo_id)) {
            this.loList.forEach((el) => {
              if (
                el.attributes.learning_object_id === res.data.lo_id &&
                !this.finalDebrief.data.includes(res.data.lo_id)
              ) {
                this.finalDebrief.data.push({
                  data: res.data.prompts.assessment_results,
                  card_type: "llm_chat",
                  name: el.attributes.learning_object.attributes.name,
                  order: el.attributes.lo_index,
                  lo_id: res.data.lo_id,
                });    
              }
            })
            this.finalDebrief.data.sort((a, b) => {
              const orderA = a.order;
              const orderB = b.order;
              if (orderA < orderB) {
                return -1;
              }
              if (orderA > orderB) {
                return 1;
              }
              return 0;
            });
            console.log(this.finalDebrief);
            console.log("unsubscribin please");
            this.$cable.unsubscribe("AssessmentResultChannel");
          }
        }
      },
      disconnected() {
        console.log("Assessment Result in Learning disconnected assessment");
      },
    },
  },
  methods: {
    handleDocumentRender() {
      this.numPages = this.$refs.pdfRef.pageCount
    },
    replaceLearnerToken(text) {
      return text.replace(
        "[learner-name]",
        this.loDetails.attributes.user_section.attributes.user.attributes
          .first_name
      );
    },
    showFeedBack() {
      utilFunctionService.showLoader();
      this.feedbackLoading = true;
      api.finalEvaluation(this.$route.params.id).then((res) => {
        if (res && res.data) {
          this.finalDebrief = res.data;
          if (this.containsLLM === true && this.isMoatCustom === true) {
            console.log(this.feedbackLoading);
            this.$modal.show("llmDialogicFinalFeedbackModal")
            this.feedbackLoading = false;
            utilFunctionService.hideLoader();
          }
          else { 
            this.$modal.show("finalFeedback");
            this.feedbackLoading = false;
            utilFunctionService.hideLoader();
          }
        }
      });
    },
    downloadPdf(link, name) {
      Axios.get(link, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", name);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error, "err");
    },
    ChatEvaluations() {
      api.getEvaluateChat(this.user_chat_id).then((res) => {
        let complete =
          res &&
          res.data &&
          res.data.data &&
          res.data.data.attributes &&
          res.data.data.attributes.complete
            ? res.data.data.attributes.complete
            : false;
        if (complete) {
          this.evaluateChatData = res.data.data;
          this.$modal.show("feedbackTask");
        } else this.createChatEvaluations();
      });
    },
    getUserChatMessages() {
      api.getUserChatMessages(this.user_chat_id).then((res) => {
        this.chatMess = [];
        this.chatMess = res.data.data;
      });
    },
    getResult() {
      api.getQuizResponse(this.quizId).then((res) => {
        this.resultStatus = true;
        this.overallMessage = res.data.data.attributes.overall_message;
        this.tallyMessage = res.data.data.attributes.tally_message;
        this.overAllAssesment = res.data.data.attributes.overall_assmnt_item;
        if (this.overAllAssesment && this.overAllAssesment.attributes) {
          this.overAllAssesment = this.overAllAssesment.attributes;
        }
        this.results = res.data.data.attributes.quiz_responses;
        this.$modal.show("seeResult");
      });
    },
    showSubmissionFeedback() {
      api
        .getSubmissionEmailDebrief(this.loDetails.id)
        .then((res) => {
          this.emailDebriefResult = res.data;
          this.$modal.show("submissionEmailResult");
        })
        .catch(() => {});
    },
    getQuizQuestions(res) {
      this.questionId = 0;
      api
        .quizQuestions(
          res.attributes.learning_object.attributes.card_detail.id,
          res.id
        )
        .then((x) => {
          this.questions = x.data.questions.data;
          this.questions.map((x) => {
            x.answer = "";
            x.status = false;
            return x;
          });
          if (
            typeof x.data.responses.data === "object" &&
            x.data.responses.data.id
          ) {
            x.data.responses.data[0] = x.data.responses.data;
          }
          if (
            x.data.responses.data[0] &&
            x.data.responses.data[0].attributes.quiz_responses.length > 0
          ) {
            this.quizId = x.data.responses.data.id;
            x.data.responses.data[0].attributes.quiz_responses.forEach(
              (element) => {
                const index = this.questions.findIndex((elem) => {
                  return (
                    parseInt(elem.id) ===
                    parseInt(element.attributes.quiz_question_id)
                  );
                });
                if (index !== -1) {
                  this.questions[index].complete = true;
                  this.questions[index].status = true;
                  this.questions[index].quizEvaluationId =
                    x.data.responses.data[0].id;
                  this.questions[index].quizId = element.id;
                  this.quizEvaluationId = x.data.responses.data[0].id;
                  if (
                    this.questions[index].attributes.question_type ==
                    "multiple_choice"
                  ) {
                    this.questions[index].answer =
                      element.attributes.learner_chosed_option.id;
                    this.questions[index].mcqStatus =
                      element.attributes.learner_chosed_option.id;
                  } else {
                    this.questions[index].answer =
                      element.attributes.learner_answer;
                  }
                }
                if (
                  x.data.responses.data[0].attributes.quiz_responses.length ==
                  this.questions.length
                ) {
                  this.leftQuizId =
                    x.data.questions.data[
                      x.data.responses.data[0].attributes.quiz_responses
                        .length - 1
                    ].id;
                  this.resultStatus = true;
                  this.questionId = this.questions.length - 1;
                } else {
                  this.leftQuizId =
                    x.data.questions.data[
                      x.data.responses.data[0].attributes.quiz_responses.length
                    ].id;
                  this.resultStatus = false;
                  this.questionId =
                    x.data.responses.data[0].attributes.quiz_responses.length;
                }
              }
            );
          } else {
            this.resultStatus = false;
          }
        });
    },
    modalInfoModal(feedback, name, values) {
      this.skillName = name;
      this.infoModalValues = values;
      this.show(feedback);
    },
    prevQuestion(i) {
      this.questionId = --i;
      this.leftQuizId = "";
    },
    nextQuestion(i) {
      this.questionId = ++i;
      this.leftQuizId = "";
    },
    getLearnerLoList() {
      api
        .learningModulesLists(this.$route.params.id)
        .then((res) => {
          this.finalFeedbackData = res.data;
          this.loList = res.data.user_learn_objs.data;
          this.showInstructorMsg = res.data.learner_org_msg;
          this.loDetails = res.data.user_learn_objs.data[0];
          this.stickyNote = this.loDetails.attributes.sticky_note;
          this.menuIndex = 0;
          this.loList.forEach((element, key) => {
            if (element.attributes.complete) {
              this.menuIndex = key + 1;
            }
            if (element.attributes.learning_object.attributes.card_type === "llm_chat" || element.attributes.learning_object.attributes.card_type === "llm_dialogic") {
              // This is code for MOAT.
              // Need it to show custom MOAT modal
              const MOAT_LO_IDS = [563, 562, 561, 560, 557, 556, 554, 553, 552, 551, 549]
              const MOAT_LEARN_MOD_ID = element.attributes.learning_object.attributes.learn_mod_id;
              if (MOAT_LO_IDS.includes(MOAT_LEARN_MOD_ID)) {
                this.isMoatCustom = true;
              }
              this.containsLLM = true;
            }
          });
          this.getChatMessages();
          if (this.$route.params.loId) {
            const loId = this.$route.params.loId;
            const id = this.loList.findIndex(
              (x) => x.attributes.learning_object_id == loId
            );
            if (id !== -1) {
              this.setModuleData();
              this.getloDetails(this.loList[id], id);
            }
          } else {
            if (this.isResetLo) {
              this.getloDetails(this.loList[this.index], this.index);
              this.isResetLo = false;
            } else if (this.menuIndex) {
              if (this.menuIndex === this.loList.length) {
                this.getloDetails(this.loList[0], this.index);
              } else {
                this.getloDetails(this.loList[this.menuIndex], this.menuIndex);
              }
            } else if (this.menuIndex === 0) {
              this.getloDetails(this.loList[this.menuIndex], this.menuIndex);
            }
          }
        })
        .catch(() => {});
    },
    setModuleData() {
      api.moduleData(this.$route.params.moduleId).then((res) => {
        console.log("setting module data in learnerCentralLo")
        console.log(res.data.data, "item");
        utilFunctionService.setLocalStorageService(
          "module",
          JSON.stringify(res.data.data)
        );
      });
    },
    updateNote(type) {
      const fd = new FormData();
      fd.append(
        "user_learn_obj[sticky_note]",
        type === "add" ? this.stickyNote : ""
      );
      api
        .updateloNotes(this.loDetails.id, this.$route.params.id, fd)
        .then(() => {
          utilFunctionService.showSuccess(
            type === "add"
              ? commonConfig.appMessages.addNote
              : commonConfig.appMessages.removeNote
          );
          if (type === "remove") {
            this.stickyNote = "";
            this.showNote = false;
            this.loList[this.index].attributes.sticky_note = "";
          } else {
            this.showNote = true;
            this.loList[this.index].attributes.sticky_note = this.stickyNote;
          }
        })
        .catch(() => {});
    },
    readMessage(index) {
      const _fd = new FormData();
      _fd.append("user_learn_obj_id", this.loDetails.id);
      api
        .readMessage(_fd)
        .then((res) => {
          if (res.status === 200) {
            this.loList[index].attributes.message_notifications = 0;
          }
        })
        .catch();
    },
    getloDetails(lo, index) {
      this.stickyNote = "";
      this.showNote = false;
      utilFunctionService.showLoader();
      this.$cable.unsubscribe("AssessmentResultChannel");
      api
        .getLearnerloDetails(this.$route.params.id, lo.id)
        .then((res) => {
          this.index = index;
          this.readMessage(index);
          this.loDetails = res.data.data;
          this.getChatMessages();
          this.refreshKey += 1;
          this.stickyNote = this.loDetails.attributes.sticky_note;
          this.isPlagiarised = this.loDetails.attributes.plagiarism_detected;
          this.plagiarismAmount = this.loDetails.attributes.plagiarism_count;
          this.cardType =
            this.loDetails.attributes.learning_object.attributes.card_type;
          if (this.loDetails.attributes.learning_object.attributes.learning_object_type === "llm_chat"){
            this.loType =
              this.loDetails.attributes.learning_object.attributes.card_detail.attributes.llm_type;
          } else {
            this.loType =
              this.loDetails.attributes.learning_object.attributes.learning_object_type;
          }
          this.evaluation_id = this.loDetails.attributes.current_evaluation_id;
          utilFunctionService.hideLoader();

          if (this.loDetails.attributes.writing_assessment) {
            this.writing_assessment =
              this.loDetails.attributes.writing_assessment;
          }
          if (
            this.loDetails.attributes.learning_object.attributes.card_type ===
            "submission_email"
          ) {
            this.userDetails =
              this.loDetails.attributes.user_section.attributes.user.attributes;
            this.toCharacters =
              this.loDetails.attributes.learning_object.attributes.card_detail.attributes.to_character;
            this.ccCharacters =
              this.loDetails.attributes.learning_object.attributes.card_detail.attributes.cc_characters;
            this.submissionDate = moment(
              this.loDetails.attributes.updated_at
            ).format("dddd, MMMM Do YYYY, h:mm:ss a");
            utilFunctionService.hideLoader();
            this.getEmailSubmission();
          }

          if (
            this.loDetails.attributes.learning_object.attributes.card_type ===
            "dialogic"
          ) {
            this.evaluation_id = this.loDetails.attributes.current_evaluation_id;
            if (this.evaluation_id) {
              api
                .getdialogicQuestions(
                  this.loDetails.attributes.learning_object.attributes
                    .card_detail.id,
                  this.loDetails.id,
                  false
                )
                .then((res) => {
                  this.questions = [...res.data.variations.data];
                  this.questions_data = [...this.questions];
                  utilFunctionService.hideLoader();
                  this.getDialogicAnswers(this.evaluation_id);
                });
            }
          }
          if (this.stickyNote !== null) {
            this.showNote = true;
            utilFunctionService.hideLoader();
          }
          if (
            this.loDetails.attributes.learning_object.attributes.card_type ===
            "submission"
          ) {
            this.submissionDate = moment(
              this.loDetails.attributes.updated_at
            ).format("dddd, MMMM Do YYYY, h:mm:ss a");
            this.has_text =
              this.loDetails.attributes.learning_object.attributes.card_detail.attributes.has_text;
            this.getSubmissionData();
            utilFunctionService.hideLoader();
          }
          if (
            this.loDetails.attributes.learning_object.attributes.card_type ===
            "quiz"
          ) {
            this.getQuizQuestions(this.loDetails);
            utilFunctionService.hideLoader();
          }

          if (
            this.loDetails.attributes.learning_object.attributes.card_type ===
            "chat"
          ) {
            this.userData = this.loDetails.attributes.user_chat_data
              ? this.loDetails.attributes.user_chat_data
              : {};
            this.chat_character =
              this.loDetails?.attributes?.learning_object?.attributes?.card_detail?.attributes?.chat_character[0];
            this.user_chat_id = this.userData.id ? this.userData.id : "";
            if (this.userData.complete) {
              this.isChatEnd = true;
            }
            if (this.user_chat_id) {
              this.getUserChatMessages();
            }
            utilFunctionService.hideLoader();
          }

          if (
            this.loDetails.attributes.learning_object.attributes.card_type ===
              "email" &&
            this.loDetails.attributes.learning_object.attributes
              .learning_object_type === "interaction"
          ) {
            this.interactiveEvaluationId =
              this.loDetails.attributes.current_evaluation_id;
            this.getEmailLists();
            utilFunctionService.hideLoader();
            if (this.loDetails) {
              this.interstitialContents =
                this.loDetails.attributes.learning_object.attributes.card_detail.attributes.interstitial_contents;
              this.selectedInterstitialContent = this.interstitialContents[0];
            }
          }
        })
        .catch(() => {});
    },
    getEmailSubmission() {
      api.getEmailSubmissions(this.loDetails.id).then((res) => {
        let dataVal = res.data.data;
        if (dataVal.length > 0) {
          this.emailData = dataVal;
          this.email_submission_response = dataVal[dataVal.length - 1]
            .attributes.email_submission_response
            ? dataVal[0].attributes.email_submission_response
            : "";
          this.status =
            dataVal[
              dataVal.length - 1
            ].attributes.email_submission_response_status;
          this.resp_id =
            dataVal[dataVal.length - 1].attributes.email_submission_response_id;
          const plagiarismList = document.querySelectorAll(
            ".plagiarism-detected"
          );
          console.log(plagiarismList);
        }
      });
    },
    showEmailSubmissionFeedback() {
      api
        .getSubmissionEmailEvaluation(this.loDetails.id, this.resp_id)
        .then((res) => {
          this.emailDebriefResult = res.data.data.attributes;
          this.$modal.show("submissionEmailEvaluation");
        })
        .catch(() => {});
    },
    getDialogicAnswers(dialogicEvaluationId) {
      this.submitquestions = [];
      api.getDialogicAnswers(dialogicEvaluationId).then((res) => {
        let ansData = res.data.data;
        if (ansData && ansData.length > 0) {
          ansData.forEach((element, i) => {
            let index = this.questions.findIndex((elem) => {
              if (
                parseInt(elem.attributes.dialogic_question_id) ===
                parseInt(element.attributes.dialogic_question_id)
              ) {
                return elem.id;
              }
            });
            let indexs = this.questions_data.findIndex((elema) => {
              if (
                parseInt(elema.attributes.dialogic_question_id) ===
                parseInt(element.attributes.dialogic_question_id)
              ) {
                return elema.id;
              }
            });
            if (indexs !== -1) {
              this.questions_data[indexs].status = true;
            }
            if (index !== -1) {
              element.attributes.question =
                this.questions[index].attributes.question;
              //console.log(element.attributes);
              if (
                element.attributes.attempt > 4 ||
                element.attributes?.follow_up_question?.length < 4
              )
                element.attributes.follow_up_question = "";
              this.submitquestions.push(element);
            }
            if (i === ansData.length - 1) {
              this.questionsDataVal();
            }
          });
        } else {
          this.questionsDataVal();
        }
      });
    },
    questionsDataVal() {
      this.questions_datas = [];
      if (this.questions_data.length > 0) {
        this.questions_data.forEach((que) => {
          if (!que.status) {
            this.questions_datas.push(que);
          }
        });
      }
    },
    dialogicEvaluations() {
      this.$modal.show("dialogicLearningData");
      utilFunctionService.showLoader();
      api.dialogicEvaluations(this.evaluation_id).then((res) => {
        utilFunctionService.hideLoader();
        let complete =
          res &&
          res.data &&
          res.data.data &&
          res.data.data.attributes &&
          res.data.data.attributes.complete
            ? res.data.data.attributes.complete
            : false;
        if (complete) {
          //console.log(
          //res.data.data.attributes.overall_assmnt_item,
          // "*** res.data.data.attributes.overall_assmnt_item"
          //);
          this.overall_assmnt_item =
            res &&
            res.data &&
            res.data.data &&
            res.data.data.attributes &&
            res.data.data.attributes.overall_assmnt_item
              ? res.data.data.attributes.overall_assmnt_item.attributes
              : "";
          this.dialogic_debrief_evaluation =
            res && res.data && res.data.data && res.data.data.attributes
              ? res.data.data.attributes.dialogic_debrief_evaluation
              : "";
          this.hideAssessmentLabel =
            res &&
            res.data &&
            res.data.data &&
            res.data.data.attributes &&
            res.data.data.attributes.hide_assessment_label;
        } else {
          this.createDialogicEvaluations();
        }
      });
    },
    createDialogicEvaluations() {
      api.createEvaluateDialogy(this.evaluation_id).then(() => {
        api.dialogicEvaluations(this.evaluation_id).then((res) => {
          this.overall_assmnt_item =
            res &&
            res.data &&
            res.data.data &&
            res.data.data.attributes &&
            res.data.data.attributes.overall_assmnt_item
              ? res.data.data.attributes.overall_assmnt_item.attributes
              : "";
          this.dialogic_debrief_evaluation =
            res && res.data && res.data.data && res.data.data.attributes
              ? res.data.data.attributes.dialogic_debrief_evaluation
              : "";
          this.hideAssessmentLabel =
            res &&
            res.data &&
            res.data.data &&
            res.data.data.attributes &&
            res.data.data.attributes.hide_assessment_label;
        });
      });
    },
    getSubmissionData() {
      api
        .getSubmissionData(this.loDetails.id)
        .then((res) => {
          let submissionData = res.data.data;
          if (submissionData) {
            if (submissionData.attributes.attachment_url) {
              let url = decodeURI(
                submissionData.attributes.attachment_url
              ).split(`"`);
              this.file = {
                name: decodeURI(url[1]),
                size: null,
                url: submissionData.attributes.attachment_url,
                file_type: decodeURI(url[1]).split(".").pop().toLowerCase()
              };
            }
            this.editorText = submissionData.attributes.user_text;
            this.interimResponse = submissionData.attributes.interim_response;
            this.status = submissionData.attributes.status;
            if (this.file.file_type === 'csv') {
              this.populateCSV();
            }
          }
        })
        .catch(() => {});
    },
    getEmailLists() {
      api.getEmailList(this.interactiveEvaluationId).then((res) => {
        this.ootoCharacters = [];
        this.emailLists = res.data.data.reverse();
        if (this.emailLists.length > 0) {
          this.nextIteration =
            this.emailLists[
              this.emailLists.length - 1
            ].attributes.next_iteration_required;
        } else {
          this.nextIteration = false;
        }
        this.emailLists.forEach((z, i) => {
          let filterEmailList = [];
          z.attributes.responses.forEach((x) => {
            let a = z.attributes.responses.filter((o) => {
              if (
                x.attributes.character.attributes.character_id ==
                o.attributes.character.attributes.character_id
              ) {
                return o;
              }
            });

            if (a.length > 1) {
              let q = filterEmailList.find(
                (x) =>
                  a[0].attributes.character.attributes.character_id ==
                  x.attributes.character.attributes.character_id
              );
              if (q) {
                return;
              }
              let ootoRes = a.find(
                (e) => e.attributes.is_ooto_response == true
              );
              filterEmailList.push(ootoRes);
              let qaFixedRes = a.find((e) => e.attributes.qa_fixed == true);
              if (qaFixedRes) {
                filterEmailList.push(qaFixedRes);
              }
              if (ootoRes && !qaFixedRes) {
                this.ootoResponseStatus = true;
                this.ootoCharacters.push(
                  a[0].attributes.character.attributes.char_first_name
                );
              }
              if (ootoRes) {
                this.showInterstitial = true;
              }
            } else {
              if (a[0].attributes.is_ooto_response) {
                this.ootoResponseStatus = true;
                this.showInterstitial = true;
                this.ootoCharacters.push(
                  a[0].attributes.character.attributes.char_first_name
                );
              }
              filterEmailList.push(a[0]);
            }
          });
          this.filterEmailIndex[i] = {
            arr: filterEmailList,
            sub: z.attributes.email_subject,
            msg: z.attributes.email,
          };
          let plagiarismList = document.querySelectorAll(
            ".plagiarism-detected"
          );
          console.log(plagiarismList);
        });
        if (this.ootoResponseStatus == true) {
          this.setOotoNotice();
        }
      });
    },
    setOotoNotice() {
      if (this.ootoCharacters.length > 1) {
        let length = this.ootoCharacters.length;
        let allCharacters = this.ootoCharacters.slice(0, length - 1).join(", ");
        allCharacters += ` and ${this.ootoCharacters[length - 1]}`;
        this.ootoNotice = `Note: You will be able to reply once ${allCharacters} have responded in depth. You will receive a notification by email when they have responded.`;
      } else if (this.ootoCharacters.length === 1) {
        let char = this.ootoCharacters[0];
        this.ootoNotice = `Note: You will be able to reply once ${char} has responded in depth. You will receive a notification by email when ${char} has responded.`;
      }
    },
    showEmailFeedBack() {
      api.emailFeedbackResponse(this.interactiveEvaluationId).then((res) => {
        this.emailfeedbackResponse = res.data.data;
        this.show("feedbackTaskFinal");
      });
    },
    selectedInterstitial(data) {
      this.selectedInterstitialContent = data;
    },
    show(modal) {
      this.$modal.show(modal);
    },
    setSubmissions(myURL, title, myWidth, myHeight) {
      let cardType =
        this.loDetails.attributes.learning_object.attributes.card_type ===
        "submission_email"
          ? "email"
          : "grading";
      if (cardType === "grading") {
        api.getUserSubmissions(this.loDetails.id).then((res) => {
          let idData = res.data && res.data.data ? res.data.data.id : "";
          this.openRubric(idData, cardType, myURL, title, myWidth, myHeight);
        });
      } else {
        api.getUserEmailSubmissions(this.loDetails.id).then((res) => {
          let resData = res.data.data;
          let idData =
            resData.length > 0
              ? resData[resData.length - 1].attributes
                  .email_submission_response_id
              : "";
          this.openRubric(idData, cardType);
        });
      }
    },
    openRubric(idData, cardType) {
      if (this.rubricWindow) {
        this.rubricWindow.close();
      }
      let learnerSubmission = {
        scorable_type: cardType,
        scorable_id: idData,
        customer_course_id:
          this.loDetails.attributes.user_section.attributes.customer_course_id,
        learning_object_id: this.loDetails.attributes.learning_object_id,
        loId: this.loDetails.id,
      };
      utilFunctionService.setLocalStorageService(
        "learnerSubmission",
        JSON.stringify(learnerSubmission)
      );
      let routeData = this.$router.resolve({
        name: "learnerSubmission",
        params: { idData },
      });
      const saveGrade = (event) => {
        if (event.data === "GradeSaved" && event.origin === window.location.origin) {
          console.log("GradeSaved")
          window.removeEventListener("message", saveGrade);
          this.refreshLearnerLOList();
        }
      }
      window.addEventListener(
        "message",
        saveGrade
      );
      this.rubricWindow = window.open(routeData.href, "_blank");
    },
    sendChatMessages() {
      const _fb = new FormData();
      _fb.append("user_learn_obj_id", this.loDetails.id);
      _fb.append("content", this.chatMessage);
      api
        .sendChatMessage(_fb)
        .then(() => {
          this.getChatMessages();
          this.chatMessage = "";
        })
        .catch(() => {
          this.getChatMessages();
          this.chatMessage = "";
        });
    },
    getChatMessages() {
      api
        .getChatMessages(this.loDetails.id)
        .then((res) => {
          this.chatMessages = [];
          this.chatMessages = res.data.data;
          this.messageDate = res.message_start_date;
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          this.chatMessages = [];
          utilFunctionService.hideLoader();
        });
      this.getSavedChatMessages();
    },
    savedMessages(id, status) {
      const _fb = new FormData();
      _fb.append("message[is_saved]", status);
      api.savedChatMessages(id, _fb).then(() => {
        this.getChatMessages();
      });
    },
    getSavedChatMessages() {
      api.getSavedChatMessages().then((msg) => {
        this.savedChatMessages = msg.data;
        this.filterChatMessages = this.savedChatMessages;
      });
    },
    setMessage(msg) {
      this.chatMessage = msg;
    },
    filterByMessage() {
      if (this.searchMessage.length > 0) {
        this.filterChatMessages = this.filterChatMessages.filter((msg) => {
          return msg.content
            .toLowerCase()
            .includes(this.searchMessage.toLowerCase());
        });
      } else {
        return (this.filterChatMessages = this.savedChatMessages);
      }
    },
    plagiarismModal(e) {
      this.$modal.show("plagiarismModal", { text: e.target.innerText });
    },
    showPlagiarismModal() {
      this.$modal.show("plagiarismModal");
    },
    resetLO() {
      this.$modal.show("resetLOConfirmModal", {
        card_type: this.cardType,
        loDetails: this.loDetails,
      });
    },
    refreshLearnerLOList() {
      utilFunctionService.showLoader();
      const currentLOIndex = this.index;
      api
        .learningModulesLists(this.$route.params.id)
        .then((res) => {
          utilFunctionService.hideLoader();
          this.finalFeedbackData = res.data;
          this.loList = res.data.user_learn_objs.data;
          this.showInstructorMsg = res.data.learner_org_msg;
          this.loDetails = res.data.user_learn_objs.data[0];
          this.stickyNote = this.loDetails.attributes.sticky_note;
          this.menuIndex = currentLOIndex;
          this.index = 0;
          this.loList.forEach((element, key) => {
            if (element.attributes.complete) {
              this.menuIndex = key + 1;
            }
          });
          this.refreshKey += 1;
          this.getloDetails(this.loList[currentLOIndex], currentLOIndex);
        })
        .catch(() => {});
    },
    showWritingAsessmentModal() {
      this.$modal.show("writeAssessModal");
    },
    populateCSV() {
      let csv_url = this.file.url;
      let csvData = new Promise((resolve) => {
        resolve(this.convertCSVToJson(csv_url));
      });
      csvData.then((data) => {
        self.tabulator = new Tabulator("#example-table", {
          height: 205,
          data: data,
          layout: "fitDataFill",
          autoColumns: true,
        });
      });
    },
    async convertCSVToJson(url) {
      const res = await fetch(url);
      if (res.ok) {
        const data = await res.text();
        var lines = data.split("\n");
        var result = [];
        var headers = lines[0].split(",");
        for (var i = 1; i < lines.length; i++) {
          var obj = {};
          var currentline = lines[i].split(",");
          for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
          }
          result.push(obj);
        }
        return result;
      }
    },
  },
  created() {
    this.getLearnerLoList();
  },
};
</script>
<style scoped lang="scss">
.emailBody {
  p {
    margin: 0 !important;
  }
}
.display-inline {
  display: inline !important;
}
.plagiarism-container {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 16px;
  border-radius: 5px;
  margin-top: 16px;
  h4 {
    padding-left: 1em;
  }
}
.plagiarism-container h4 {
  font-size: 18px;
  font-weight: bold;
  margin-left: 8px;
}
.plagiarism-counter {
  margin-right: 8px;
}
.plagiarism-detected {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  .plagiarismIcon {
    position: absolute;
    right: -40px;
    transition: all 0.5s ease-in-out;
    opacity: 0.5;
  }
}
.plagiarism-detected:hover {
  background-color: #f5e69f;
  .plagiarismIcon {
    position: absolute;
    right: -40px;
    opacity: 1;
  }
}
.learn-sidebar-title {
  .plagiarismIcon {
    position: absolute;
    top: 30px;
    left: -11px;
  }
}
.display-inline {
  display: inline !important;
}

.submission-update {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  span {
    font-weight: bold;
  }
  p {
    margin-top: 2rem;
  }
}

.btn {
  color: #ffffff;
}

.btn:hover {
  color: #ffffff;
}

.primary:hover {
  background-color: #2f947c;
}
</style>
