<template>
  <div class="learner-container">
    <div class="messages-area" v-for="message in messages" :key="message.id">
      <transition name="up">
        {{ message }}
        <div v-if="message.question" class="bubble robot-bubble">
          <div class="character-container">
            <figure class="avatar">
              <!-- <img src="../assets/images/default-user.svg" alt="" /> -->
            </figure>
            {{ characterName }}
          </div>
          {{ message.question }}
        </div>
      </transition>
      <transition name="up">
        <div v-if="message.answer" class="bubble learner-bubble">
          {{ message.answer }}
        </div>
      </transition>
      <transition name="up">
        <div v-if="message.response" class="bubble robot-bubble">
          <div
            v-for="(characterResponse, index) in message.response"
            :key="index"
          >
            <div class="character-container">
              <figure class="avatar">
                <!-- <img src="../assets/images/default-user.svg" alt="" /> -->
              </figure>
              <h4 class="character-name">{{ characterName }}</h4>
            </div>
            <div class="character-response">
              {{ characterResponse.response }}
            </div>
          </div>
        </div>
      </transition>
    </div>
    <hr />
    <div class="assessment-container">
      <div class="final-feedback-title">
        <h1>Conversation Assessment</h1>
      </div>
      <div class="assessment-content">
        <div v-if="ifLearnerAssessments" class="container">
          <div v-for="(feedback, name, index) in feedbackData" class="row" :key="feedback[index]">
            <h2>{{ feedback.data.attributes.custom_label || feedback.data.attributes.skill_set_title }}</h2>
            <p class="feedback-result" v-if="feedback.data.attributes.show_pass_fail_labels">{{ feedback.data.attributes.assessment_result || feedback.data.attributes.hit_feed_req_item_label }}</p>
            <p v-if="feedback.data.attributes.show_points">{{ feedback.data.attributes.earned_points }}/{{ feedback.data.attributes.possible_points }}</p>
            <p v-html="feedback.data.attributes.llm_feedback"></p>
          </div>
        </div>
        <div v-else class="container">
          <div v-for="(feedback, name, index) in feedbackData" class="row" :key="feedback[index]">
            <h2 v-if="feedback.show_labels">{{ feedback.skill_label || feedback.skill_name }}</h2>
            <h2 v-else>{{ feedback.skill_name }}</h2>
            <p v-if="feedback.show_labels" class="feedback-result">{{ feedback.result }}</p>
            <p>{{ feedback.feedback }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
export default {
  name: "llm-chat",
  props: ["active_data", "nextStatus", "user_details", "lo_details"],
  data() {
    return {
      messages: [],
      userAnswer: "",
      loading: false,
      assessmentLoading: false,
      feedback: "",
      hasStarted: false,
      finishedLoading: false,
      numberOfAttempts: 0,
      attemptsLimit: 3,
      llmComplete: false,
      showAssessment: false,
      showFeedback: false,
      dialogicQuestions: [],
      characterName: "",
      feedbackData: [],
      ifLearnerAssessments: false
    };
  },
  methods: {
    submitAssessmentRequest() {
      api.getLlmChatAssessment(this.lo_details.attributes.current_evaluation_id).then((res) => {
        console.log(res);
        const response = res.data.data;
        if (response.attributes.learner_assessments.length > 0) {
          this.feedbackData = response.attributes.learner_assessments;
          this.ifLearnerAssessments = true;
        } else {
          this.feedbackData = response.attributes.assessment_results;
        }
      });
    },
    resetLlmConversation() {
      let userDetail = this.user_details.user_section.attributes.user;
      let payload = {
        student_id: userDetail.id,
        lo_id: this.user_details.learning_object_id,
      };
      api.resetLlmConversation(payload).then((res) => {
        console.log(res);
        this.assessmentLoading = false;
      });
    },
    getLlmConversation() {
      let payload = {
        lo_id: this.active_data.id,
        user_learn_obj_id : this.lo_details.id
      };
      api.getLlmChatHistory(payload).then((res) => {
        let chatHistoryData = res.data.data;
        console.log(res);
        chatHistoryData.forEach((chatEl) => {
          this.hasStarted = true;
          let response = "";
          if (chatEl.attributes.response.length > 0) {
            response = chatEl.attributes.response;
          }
          this.messages.push({
            answer: chatEl.attributes.body,
            response: response,
          });
        });
      });
    },
  },
  mounted() {
    this.llmComplete = this.user_details.complete;
    let cardDetail = this.active_data.attributes.card_detail;
    this.characterName = cardDetail.attributes.character_name;

    this.getLlmConversation();
    this.submitAssessmentRequest();
  },
};
</script>

<style scoped lang="scss">
.learner-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messages-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 45px;
}

.bubble {
  border-radius: 15px;
  padding: 16px;
  margin-bottom: 16px;
  max-width: 50%;
}

.learner-bubble {
  background: #3dbc9e;
  color: #faf9f6;
  font-weight: bold;
  justify-self: flex-end;
  align-self: flex-end;
  text-align: right;
  width: fit-content;
}

.robot-bubble {
  color: #faf9f6;
  font-weight: bold;
  justify-self: flex-start;
  align-self: flex-start;
  width: fit-content;
  position: relative;
  min-width: 300px;
  padding: 0;

  .character-response {
    margin-bottom: 12px;
    background: #3d9bbc;
    padding: 16px;
    color: #faf9f6;
    font-weight: bold;
    justify-self: flex-start;
    align-self: flex-start;
    width: fit-content;
    position: relative;
    min-width: 300px;
    border-radius: 15px
  }
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
}

.button {
  padding: 8px;
  border-radius: 5px;
  background: white;
  border: 2px solid green;
  margin-top: 16px;
}

.button:hover {
  background: green;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.up-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.up-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(30px);
}

.feedback {
  width: 50%;
  margin: 25px auto;
  text-align: left;
  line-height: 1.6;
}

.spacer {
  display: block;
  width: 50%;
  margin: 0 auto;
  height: 1px;
  background: black;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 128px;
}

.messages-buttons-container {
  display: flex;
  width: 100%;
  padding: 10px;
  position: relative;
  min-height: 75px;
  max-height: 450px;
}

.messages-container {
  display: flex;
  width: 100%;
  padding: 10px;
  position: relative;
  min-height: 75px;
  max-height: 450px;
  flex-direction: column;
  overflow: scroll;
}

.input-area {
  color: black;
  width: 400px;
  min-height: 75px;
  padding: 16px;
  max-height: 450px;
}

.submit-button {
  border: none;
  font-size: 14px;
  padding: 6px 10px;
  border: 1px solid green;
  margin-top: 0;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.avatar {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 2px solid rgba(255, 255, 255, 0.24);
  margin-right: 16px;

  img {
    width: 100%;
    height: auto;
  }
}

.character-container {
  position: absolute;
  z-index: 1;
  top: -50px;
  left: 0px;
  background: #3d9bbc;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.character-loading {
  position: absolute;
  z-index: 1;
  top: -50px;
  left: 0px;
  background: #3d9bbc;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.character-name {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
}

.hide-audio {
  display: none;
}

#talk_button {
  border: 2px solid green;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.5s;
  border-radius: 5px;
}

#speech_button {
  border: 2px solid green;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.5s;
  border-radius: 5px;
}

#sp_embed {
  display: block;
  position: relative;
  overflow: hidden;
}

.assessment-content {
  padding-top: 32px;
  p {
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
}

h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.container {
  margin: 0 16px;
  width: 100%;
}

</style>